import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import { validaModelo } from "../validaciones/Validaciones";

function RegistrarModelo(){
    const [nombreModelo, setNombreModelo] = useState("");
    const [idMarca, setIdMarca] = useState(0);
    const [marcasList, setMarcas] = useState([]);
    const [show, setShow] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""
    useEffect(() => {
        listarMarcas();
    }, []);
/*  Listamos las marcas de vehiculos habilitadas */
const listarMarcas = async() =>{
    await Axios.get(`${config.apiUrl}/api/marcas/`,{headers: {'Authorization': token,},})
    .then((response) => {setMarcas(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de marcas:", error.response);});
};
/* Registramos nuestro Tipo de Insumo */
    const registrarModelo = async() => {
        if (validaModelo(idMarca, nombreModelo)){
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaModelo(idMarca, nombreModelo)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosModelo = {
                nombre: nombreModelo.toUpperCase(),idMarca: idMarca,estado: 1
            }
            await Axios.post(`${config.apiUrl}/api/modelos`,datosModelo,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                setShow(false);
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el modelo.</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-car'></span>&nbsp;Registrar Nuevo Modelo</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-car"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setIdMarca(event.target.value)}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={1} defaultValue={idMarca}>
                                <option value={0}>--SELECCIONE--</option>
                                {marcasList.length > 0 ?
                                    marcasList.map((val) => {
                                    return (
                                        <option value={val.idMarca} key={val.idMarca} selected={val.idMarca === idMarca ? "selected" : ""}>{val.nombreMarca}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-cog"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="nombreMarca" name="nombreMarca" type="text" placeholder="Descripción" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setNombreModelo(event.target.value);}} required tabIndex={2}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={registrarModelo} tabIndex={2}><span className="fa fa-floppy-o"></span>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarModelo