import React from "react";
import {useState, useEffect} from "react";  
import config from '../../config';  
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import {validaCambioContrasena} from "../validaciones/Validaciones";

function CambioContrasenaUsuario({id}){
    const [idPersona, setIdPersona] = useState("");
    const [nombreUsuario, setNombres] = useState("");
    const [apellidosUsuario, setApellidos] = useState("");
    const [contrasenaUsuario, setContrasena] = useState("");
    const [confirmarContrasena, setConfirmar] = useState("");
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        getDatosUsuario();
        return () =>{
        }
    }, []);
/* Obtengo los datos del Cliente */
const getDatosUsuario = async () => {
    await Axios.get(`${config.apiUrl}/api/usuarios/` + id,{headers:{'Content-Type':'application/json','Authorization': token}})
    .then((response) => {
        setIdPersona(response.data.idPersona)
        setNombres(response.data.nombreUsuario)
        setApellidos(response.data.apellidoUsuario)
    })
    .catch((error) => {
        console.error("Atención: Hubo un problema al recuperar los datos del Usuario.")
    });
};
/*  Fin */
    const editarUsuario = async() => {
        if (validaCambioContrasena(contrasenaUsuario, confirmarContrasena)){// Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaCambioContrasena(contrasenaUsuario, confirmarContrasena)
            SweetAlert(mensaje, "error","<strong>Atención</strong>") 
        }else{ 
            const datosUsuario = {
                contrasena: contrasenaUsuario
            }
            await Axios.put(`${config.apiUrl}/api/usuarios/contrasena/` + id,datosUsuario,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroActualizado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al editar el usuario.</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-address-book'></span>&nbsp;Editar Usuario</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-10 col-md-5 col-lg-10">
                            <span className="textosNormal">{nombreUsuario}&nbsp;{apellidosUsuario}</span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-lock"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="contrasena" name="contrasena" type="password" placeholder="Contraseña" className="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setContrasena(event.target.value);}} maxLength={16}/> 
                            </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-unlock-alt"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="confirmarContrasena" name="confirmarContrasena" type="password" placeholder="Confirmar Contraseña" className="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setConfirmar(event.target.value);}} maxLength={16}/>
                            </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={editarUsuario}><h5><span className="fa fa-refresh"></span></h5>&nbsp;Actualizar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left">
                            <li className="textosNormal">La contraseña debe tener un mínimo de 10 caracteres.</li>
                            <li className="textosNormal">La contraseña debe contener al menos una letra mayúscula.</li>
                            <li className="textosNormal">La contraseña debe contener al menos una letra minúscula.</li>
                            <li className="textosNormal">La contraseña debe contener al menos un número.</li>
                            <li className="textosNormal">La contraseña debe contener al menos un símbolo.</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default CambioContrasenaUsuario