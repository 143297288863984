import React from "react";
import config from '../../config'
import { useState, useEffect } from "react";    
import Axios from "axios";
import Swal from "sweetalert2";
import SweetAlert from "../SweetAlert";
import { validarCliente} from "../validaciones/Validaciones";

function RegistrarClientes(){
    const [nombresCliente, setNombres] = useState("");
    const [apellidosCliente, setApellidos] = useState("");
    const [rutCliente, setRut] = useState("");
    const [emailCliente, setEmail] = useState("");
    const [telefonoCliente, setTelefono] = useState("");
    const token = localStorage.getItem('token');
    const [mensajeError, setMensajeError] = useState('');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        //registrarCliente();
    }, []);

    const limpiarCampos = () => {
        setRut("");
        setNombres("");
        setApellidos("");
        setTelefono("");
        setEmail("");
        setRut("");
      };

    const registrarCliente = async() => {
        if (validarCliente(rutCliente, nombresCliente, apellidosCliente, emailCliente, telefonoCliente)){
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validarCliente(rutCliente, nombresCliente, apellidosCliente, emailCliente, telefonoCliente)
            setMensajeError(mensaje);
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            /*const mensajeError = validarCliente(nombresCliente, apellidosCliente, rutCliente, emailCliente, telefonoCliente);  */
            const datosCliente = {
                rut: rutCliente.toUpperCase(),nombres: nombresCliente.toUpperCase(),apellidos: apellidosCliente.toUpperCase(),
                telefono: (telefonoCliente === '' ? '' : telefonoCliente), email: emailCliente.toUpperCase(),
            }
            await Axios.post(`${config.apiUrl}/api/clientes`,datosCliente,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo);
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al crear el registro</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-address-book'></span>&nbsp;Registrar Cliente</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="fname" name="name" type="text" placeholder="Nombres" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNombres(event.target.value);}} required tabIndex={1} maxLength={60}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'>
                            <input id="fname" name="name" type="text" placeholder="Apellidos" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setApellidos(event.target.value);}} required tabIndex={2} maxLength={60}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setRut(event.target.value);}} maxLength={11} tabIndex={3}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-envelope"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="email" name="email" type="text" placeholder="email@dominio.cl" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setEmail(event.target.value);}} tabIndex={4} maxLength={100}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-phone-square"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="fname" name="name" type="text" placeholder="912345678" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setTelefono(event.target.value);}} tabIndex={5} maxLength={9}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={registrarCliente} tabIndex={6}><span className="fa fa-floppy-o"></span>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarClientes