var Fn = {
	// Valida el rut con su cadena completa "XXXXXXXX-X"
	validaRut : function (rutCompleto) {
		if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test( rutCompleto ))
			return false;
		var tmp 	= rutCompleto.split('-');
		var digv	= tmp[1]; 
		var rut 	= tmp[0];
		if ( digv == 'K' ) digv = 'k' ;
		return (Fn.dv(rut) == digv );
	},
	dv : function(T){
		var M=0,S=1;
		for(;T;T=Math.floor(T/10))
			S=(S+T%10*(9-M++%6))%11;
		return S?S-1:'k';
	}
}

const validarEmail = (email) =>{
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const validarTelefono = (telefono) => {
    // Se definen las expresiones regulares
    const patronChileno = /^(\+?56)?[ -]?([0-9]{9})$/;
    // Se realiza la validación
    if (!patronChileno.test(telefono)) {
       return false;
    }
    return true;
}
const validarFormato = (texto) => {
    // La expresión regular coincide con letras (a-zA-Z) y espacios
    const regex = /^[a-zA-Z\s]+$/;
    // Comprobar si el texto cumple con el formato válido
    return regex.test(texto);
}
/* Validación de fecha */
const validaFechaActual = (fecha) =>{
    var resultado = true;
    var hoy = new Date();
    var dd = String(hoy.getDate()).padStart(2, '0');
    var mm = String(hoy.getMonth() + 1).padStart(2, '0'); 
    var yyyy = hoy.getFullYear();
    hoy = yyyy + '-' + mm + '-' + dd; 
    if (fecha > hoy){ resultado = false} // Si es mayor a la fecha actual, devuelvo false (fecha incorrecta)
    return resultado;
}
/* Validación para contraseñas */
function validarPassword(password) {
    let error = false;
    if (password.length < 10) { //Contraseña largo menor a 10
       error = true;
    }
    if (!/[A-Z]/.test(password)) { //Se valida si la contraseña posea una letra Mayúscula
        error = true;
    }
    if (!/[a-z]/.test(password)) { //Se valida si la contraseña posea una letra minúscula
        error = true;
    }
    if (!/[0-9]/.test(password)) { //Se valida si la contraseña posea un número
        error = true;
    }
    if (!/[^A-Za-z0-9]/.test(password)) { //Se valida si la contraseña posea un simbolo
        error = true;
    }
    return error; //Si devuelve verdadero, quiere decir que hay un error
   }
/* Validaciones por cada Componente de registro */
/* Validación para el formulario Cliente */
export const validarCliente = (rutCliente, nombreCliente, apellidoCliente, emailCliente, telefonoCliente )=>{
    let mensaje = '';
    if (rutCliente === ''){
        mensaje = mensaje + '<li>El rut es requerido.</li>';
    }else{
        if (!Fn.validaRut(rutCliente)){
            mensaje = mensaje + '<li>El rut ingresado es incorrecto.</li>';
        }
    }
    if (nombreCliente===''){
        mensaje = mensaje + '<li>El nombre del cliente es requerido.</li>';
    }else{
        if (!validarFormato(nombreCliente)){
            mensaje = mensaje + '<li>El formato del nombre ingresado es incorrecto.</li>';
        }
    }
    if (apellidoCliente===''){
        mensaje = mensaje + '<li>El apellido del cliente es requerido.</li>';
    }else{
        if (!validarFormato(apellidoCliente)){
            mensaje = mensaje + '<li>El formato del apellido ingresado es incorrecto.</li>';
        }
    }
    if (emailCliente===''){
        mensaje = mensaje + '<li>El email del cliente es requerido.</li>';
    }else{
        if (!validarEmail(emailCliente)){
            mensaje = mensaje + '<li>El email del cliente es incorrecto.</li>';
        }
    }
    if (telefonoCliente !=='' && telefonoCliente !== undefined){
        if (telefonoCliente.length < 9){
            mensaje = mensaje + '<li>El teléfono ingresado es incorrecto. Ej: 912345678</li>';
        } 
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validación para la asociación de vehiculos */
export const validarRegistroVehiculoCliente = (patenteVehiculo, modeloVehiculo)=>{
    let mensaje = '';
    if (patenteVehiculo===''){
        mensaje = mensaje + '<li>La patente del vehiculo es requerida.</li>';
    }
    if (modeloVehiculo === '' || modeloVehiculo === 0){
        mensaje = mensaje + '<li>El modelo del vehiculo es requerido.</li>';
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validación para gastos */
export const validarGasto = (nroDocumento, idProveedor, idInsumo, cantidadInsumos, precioCompra, precioVenta, fechaRecepcion)=>{
    let mensaje = '';
    if (nroDocumento===''){
        mensaje = mensaje + '<li>El número de documento requerida.</li>';
    }
    if (idProveedor === '' || parseFloat(idProveedor) === 0){
        mensaje = mensaje + '<li>El proveedor es requerido.</li>';
    }
    if (idInsumo === '' || parseFloat(idInsumo) === 0){
        mensaje = mensaje + '<li>El Insumo es requerido.</li>';
    }
    if (cantidadInsumos === '' || parseFloat(cantidadInsumos) === 0){
        mensaje = mensaje + '<li>La cantidad de insumos es requerida.</li>';
    }
    if (precioCompra === '' || parseFloat(precioCompra) === 0){
        mensaje = mensaje + '<li>El precio de compra es requerido.</li>';
    }
    if (precioVenta === '' || parseFloat(precioVenta) === 0){
        mensaje = mensaje + '<li>El precio de venta es requerido.</li>';
    }else{
        if (parseFloat(precioVenta) < parseFloat(precioCompra)){
            mensaje = mensaje + '<li>El precio de venta no puede ser menor al precio de compra.</li>';
        }   
    }
    if (fechaRecepcion === '' || fechaRecepcion === '1900-01-01'){
        mensaje = mensaje + '<li>La fecha de recepción es requerida.</li>';
    }else{
        if (!validaFechaActual(fechaRecepcion)){
            mensaje = mensaje + '<li>La fecha de recepción, no puede ser mayor a la fecha actual.</li>';
        }   
    }  
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validación para el registro de insumos */
export const validarInsumo = (codigoInsumo, descripcionInsumo, tipoInsumo)=>{
    let mensaje = '';
    if (codigoInsumo===''){
        mensaje = mensaje + '<li>El código del Insumo es requerido.</li>';
    }else{
        if (codigoInsumo.length < 4){
            mensaje = mensaje + '<li>El Largo del código no puede ser menor a 4 carácteres.</li>';
        }     
    }
    if (descripcionInsumo === ''){
        mensaje = mensaje + '<li>La descripción del Insumo es requerida.</li>';
    }else{
        if (descripcionInsumo.length < 4){
            mensaje = mensaje + '<li>La descripción ingresada es incorrecta.</li>';
        } 
    }
    if (tipoInsumo === '' || parseFloat(tipoInsumo) === 0){
        mensaje = mensaje + '<li>El tipo de insumo requerido.</li>';
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validación de notificación */
export const validaNotificacionEmail = (tituloNotificacion,contenidoNotificacion)=>{
    let mensaje = '';
    if (tituloNotificacion===''){
        mensaje = mensaje + '<li>El título de la notificación es requerido.</li>';
    }else{
        if (tituloNotificacion.length < 4){
            mensaje = mensaje + '<li>El Largo del título no puede ser menor a 4 carácteres.</li>';
        }     
    }
    if (contenidoNotificacion === ''){
        mensaje = mensaje + '<li>El contenido de la notificación es requerido.</li>';
    }else{
        if (contenidoNotificacion.length < 10){
            mensaje = mensaje + '<li>El contenido de la notificación, no puede ser de largo menor a 10 carácteres.</li>';
        } 
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validación de proveedores */
export const validarProveedor = (rut, razonSocial, direccion, telefono, email, numeroCuenta, banco, tipoCuenta )=>{
    let mensaje = '';
    if (rut === ''){
        mensaje = mensaje + '<li>El rut es requerido.</li>';
    }else{
        if (!Fn.validaRut(rut)){
            mensaje = mensaje + '<li>El rut ingresado es incorrecto.</li>';
        }
    }
    if (razonSocial===''){
        mensaje = mensaje + '<li>La razón social del proveedor es requerida.</li>';
    }else{
        if (!validarFormato(razonSocial)){
            mensaje = mensaje + '<li>La razón social del proveedor solo debe contener letras.</li>';
        }
    }
    if (direccion===''){
        mensaje = mensaje + '<li>La dirección del proveedor es requerida.</li>';
    }else{
        if (direccion.length < 10){
            mensaje = mensaje + '<li>La dirección del proveedor debe ser mayor a 10 carácteres.</li>';
        }
    }
    if (telefono !=='' || telefono !== undefined){
        if (telefono.length < 9){
            mensaje = mensaje + '<li>El teléfono ingresado es incorrecto. Ej: 912345678</li>';
        } 
    }
    if (email===''){
        mensaje = mensaje + '<li>El email del proveedor es requerido.</li>';
    }else{
        if (!validarEmail(email)){
            mensaje = mensaje + '<li>El email del proveedor es incorrecto.</li>';
        }
    }
    if (numeroCuenta===''){
        mensaje = mensaje + '<li>El número de cuenta del proveedor es requerido.</li>';
    }else{
        if (numeroCuenta.length < 8){
            mensaje = mensaje + '<li>El número de cuenta del proveedor debe contener mínimo 8 carácteres.</li>';
        }
    }
    if (banco === '' || parseFloat(banco) === 0){
        mensaje = mensaje + '<li>El banco del proveedor es requerido.</li>';
    }
    if (tipoCuenta === '' || parseFloat(tipoCuenta) === 0){
        mensaje = mensaje + '<li>El tipo de cuenta bancaria es requerida.</li>';
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos el registro de un trabajo */
export const validaTrabajo = (detalleTrabajo, fechaTrabajo, proxMantencion, requiereNotificacion, manoObra, idVehiculo, idEstadoTrabajo, observacionesTrabajo )=>{
    let mensaje = '';
    if (detalleTrabajo===''){
        mensaje = mensaje + '<li className="textosNormal">La descripción del trabajo es requerida.</li>';
    }else{
        if (detalleTrabajo.length < 10){
            mensaje = mensaje + '<li>La descripción del trabajo debe contener mínimo 10 carácteres.</li>';
        }
    }
    if (fechaTrabajo==='' || fechaTrabajo === '1900-01-01'){
        mensaje = mensaje + '<li className="textosNormal">La fecha de trabajo es requerida.</li>';
    }else{
        if (!validaFechaActual(fechaTrabajo)){
            mensaje = mensaje + '<li className="textosNormal">La fecha de trabajo, no puede ser mayor a la fecha actual.</li>';
        }
    }
    if (requiereNotificacion){
        if (proxMantencion === '1900-01-01'){
            mensaje = mensaje + '<li className="textosNormal">La fecha de próxima mantención, es requerida.</li>';
        }else{
            if (validaFechaActual(proxMantencion)){
                mensaje = mensaje + '<li className="textosNormal">La fecha de próxima mantención debe ser mayor a la actual.</li>';
            }  
        }
    }
    if (manoObra ===''){
        mensaje = mensaje + '<li className="textosNormal">El costo de mano obra, es requerido.</li>';
    }
    if (idVehiculo==='' || parseFloat(idVehiculo) === 0){
        mensaje = mensaje + '<li className="textosNormal">Debe asociar un vehículo previamente registrado al trabajo.</li>';
    }
    if (idEstadoTrabajo ==='' || parseFloat(idEstadoTrabajo) === 0){
        mensaje = mensaje + '<li className="textosNormal">El estado de trabajo es requerido.</li>';
    }
    if (observacionesTrabajo !== ''){
        if (observacionesTrabajo.length < 15){
            mensaje = mensaje + '<li className="textosNormal">La observacion del trabajo, debe tener un mínimo de 15 carácteres.</li>';
        }
    }
    if (mensaje !== '' ? mensaje = '<ul className="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos la asociación de un insumo a un trabajo */
export const validaAsociacionInsumo = (idInsumo,datoCantidadInsumo, cantidadInsumos )=>{
    let mensaje = '';
    if (idInsumo==='' || parseFloat(idInsumo) === 0){
        mensaje = mensaje + '<li>Un insumo es requerido.</li>';
    }
    if (parseFloat(cantidadInsumos) <= 0 || cantidadInsumos === ''){
        mensaje = mensaje + '<li>La cantidad de insumos es requerida, o no puede ser una cantidad menor o igual a 0.</li>';
    }else{
        if (parseFloat(cantidadInsumos) < 0){
            mensaje = mensaje + '<li>La cantidad de insumos a utilizar es incorrecta.</li>';
        }else{
            if (parseFloat(cantidadInsumos) > parseFloat(datoCantidadInsumo)){
                mensaje = mensaje + '<li>La cantidad de insumos no puede superar los ' + datoCantidadInsumo + '</li>';
            }
        }
    }
    if (mensaje !== '' ? mensaje = '<ul>' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos el cambio de Contraseña */
export const validaCambioContrasena = (contrasenaUsuario, contrasenaConfirmar) =>{
    let mensaje = '';
    if (contrasenaUsuario === ''){
        mensaje = mensaje + '<li>La contraseña del usuario es requerida.</li>';
    }else{
        if (validarPassword(contrasenaUsuario)){
            mensaje = mensaje + '<li>La contraseña tiene el formato incorrecto.</li>';
        }
    }
    if (contrasenaConfirmar === ''){
        mensaje = mensaje + '<li>Debe confirmar la contraseña ingresada.</li>';
    }else{
        if (contrasenaUsuario !== contrasenaConfirmar){
            mensaje = mensaje + '<li>Las contraseñas no coinciden.</li>';
        }
    }
    return mensaje;
}
/* Validamos el registro de usuario */
export const validaUsurio = (rutUsuario, nombreUsuario, apellidosUsuario, telefonoUsuario, emailUsuario, idTipoPerfil, contrasenaUsuario, confirmarContrasena )=>{
    let mensaje = '';
    if (rutUsuario === ''){
        mensaje = mensaje + '<li>El rut es requerido.</li>';
    }else{
        if (!Fn.validaRut(rutUsuario)){
            mensaje = mensaje + '<li>El rut ingresado es incorrecto.</li>';
        }
    }
    if (nombreUsuario === ''){
        mensaje = mensaje + '<li>El nombre del usuario es requerido.</li>';
    }else{
        if (!validarFormato(nombreUsuario)){
            mensaje = mensaje + '<li>El formato del nombre ingresado es incorrecto.</li>';
        }
    }
    if (apellidosUsuario === ''){
        mensaje = mensaje + '<li>El apellido del usuario es requerido.</li>';
    }else{
        if (!validarFormato(apellidosUsuario)){
            mensaje = mensaje + '<li>El formato del apellido ingresado es incorrecto.</li>';
        }
    }
    if (telefonoUsuario !=='' || telefonoUsuario !== undefined){
        if (telefonoUsuario.length < 9){
            mensaje = mensaje + '<li>El teléfono ingresado es incorrecto. Ej: 912345678</li>';
        } 
    }
    if (emailUsuario===''){
        mensaje = mensaje + '<li>El email del usuario es requerido.</li>';
    }else{
        if (!validarEmail(emailUsuario)){
            mensaje = mensaje + '<li>El email del usuario es incorrecto.</li>';
        }
    }
    if (idTipoPerfil === '' || parseFloat(idTipoPerfil) ===0){
        mensaje = mensaje + '<li>El tipo de perfil para el usuario es requerido.</li>';
    }
    if (contrasenaUsuario === ''){
        mensaje = mensaje + '<li>La contraseña del usuario es requerida.</li>';
    }else{
        if (validarPassword(contrasenaUsuario)){
            mensaje = mensaje + '<li>La contraseña tiene el formato incorrecto.<br>Debe de ser de min. largo 10, poseer 1 número, 1 letra minúscula, 1 letra mayúscula y un simbolo.</li>';
        }
    }
    if (confirmarContrasena === ''){
        mensaje = mensaje + '<li>Debe confirmar la contraseña ingresada.</li>';
    }else{
        if (contrasenaUsuario !== confirmarContrasena){
            mensaje = mensaje + '<li>Las contraseñas no coinciden.</li>';
        }
    }
    if (mensaje !== '' ? mensaje = '<ul className="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos el registro de usuario */
export const validaEdicionUsurio = (rutUsuario, nombreUsuario, apellidosUsuario, telefonoUsuario, emailUsuario, idTipoPerfil, contrasenaUsuario, confirmarContrasena )=>{
    let mensaje = '';
    if (rutUsuario === ''){
        mensaje = mensaje + '<li>El rut es requerido.</li>';
    }else{
        if (!Fn.validaRut(rutUsuario)){
            mensaje = mensaje + '<li>El rut ingresado es incorrecto.</li>';
        }
    }
    if (nombreUsuario === ''){
        mensaje = mensaje + '<li>El nombre del usuario es requerido.</li>';
    }else{
        if (!validarFormato(nombreUsuario)){
            mensaje = mensaje + '<li>El formato del nombre ingresado es incorrecto.</li>';
        }
    }
    if (apellidosUsuario === ''){
        mensaje = mensaje + '<li>El apellido del usuario es requerido.</li>';
    }else{
        if (!validarFormato(apellidosUsuario)){
            mensaje = mensaje + '<li>El formato del apellido ingresado es incorrecto.</li>';
        }
    }
    if (telefonoUsuario !=='' || telefonoUsuario !== undefined){
        if (telefonoUsuario.length < 9){
            mensaje = mensaje + '<li>El teléfono ingresado es incorrecto. Ej: 912345678</li>';
        } 
    }
    if (emailUsuario===''){
        mensaje = mensaje + '<li>El email del usuario es requerido.</li>';
    }else{
        if (!validarEmail(emailUsuario)){
            mensaje = mensaje + '<li>El email del usuario es incorrecto.</li>';
        }
    }
    if (idTipoPerfil === '' || parseFloat(idTipoPerfil) ===0){
        mensaje = mensaje + '<li>El tipo de perfil para el usuario es requerido.</li>';
    }
    if (contrasenaUsuario !== ''){
        if (validarPassword(contrasenaUsuario)){
            mensaje = mensaje + '<li>La contraseña tiene el formato incorrecto.<br>Debe de ser de min. largo 10, poseer 1 número, 1 letra minúscula, 1 letra mayúscula y un simbolo.</li>';
        }
    }
    if (confirmarContrasena === '' && contrasenaUsuario !== ''){
        mensaje = mensaje + '<li>Debe confirmar la contraseña ingresada.</li>';
    }else{
        if (contrasenaUsuario !== confirmarContrasena){
            mensaje = mensaje + '<li>Las contraseñas no coinciden.</li>';
        }
    }
    if (mensaje !== '' ? mensaje = '<ul className="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos el registro de Tipos de Insumos */
export const validaTipoInsumo = (nombreTipoInsumo)=>{
    let mensaje = '';
    if (nombreTipoInsumo===''){
        mensaje = mensaje + '<li>El nombre del tipo de insumo es requerido.</li>';
    }else{
        if (nombreTipoInsumo.length < 4){
            mensaje = mensaje + '<li>El Largo del nombre no puede ser menor a 4 carácteres.</li>';
        }     
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos el registro de un vehículo */
export const validaVehiculo = (nroPatente, idCliente, idModelo)=>{
    let mensaje = '';
    if (nroPatente===''){
        mensaje = mensaje + '<li>La patente es requerida.</li>';
    }else{
        if (nroPatente.length < 6){
            mensaje = mensaje + '<li>El nro. de patente no es válido.</li>';
        }     
    }
    if (idCliente==='' || parseFloat(idCliente) === 0){
        mensaje = mensaje + '<li>Debe haber un cliente asociado.</li>';
    }
    if (idModelo === '' || parseFloat(idModelo) === 0){
        mensaje = mensaje + '<li>El modelo del vehículo es requerido.</li>';
    }  
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos una marca */
/* Validamos el registro de Marcas */
export const validaMarca = (nombreMarca)=>{
    let mensaje = '';
    if (nombreMarca===''){
        mensaje = mensaje + '<li>El nombre de la marca es requerida.</li>';
    }else{
        if (nombreMarca.length < 2){
            mensaje = mensaje + '<li>El Largo del nombre no puede ser menor a 2 carácteres.</li>';
        }     
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}
/* Validamos el registro de Modelos */
export const validaModelo = (idMarca, nombreModelo)=>{
    let mensaje = '';
    if (idMarca ==='' || parseFloat(idMarca) === 0){
        mensaje = mensaje + '<li>La marca es requerida.</li>';
    }
    if (nombreModelo===''){
        mensaje = mensaje + '<li>El nombre del modelo es requerido.</li>';
    }else{
        if (nombreModelo.length < 3){
            mensaje = mensaje + '<li>El Largo del nombre no puede ser menor a 3 carácteres.</li>';
        }     
    }
    if (mensaje !== '' ? mensaje = '<ul classname="text-justify">' + mensaje + '</ul>' : mensaje = '')
    return mensaje;
}