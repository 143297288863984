import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import {validaEdicionUsurio} from "../validaciones/Validaciones";

function EditarUsuario({id}){
    const [idPersona, setIdPersona] = useState("");
    const [nombreUsuario, setNombres] = useState("");
    const [apellidosUsuario, setApellidos] = useState("");
    const [rutUsuario, setRut] = useState("");
    const [emailUsuario, setEmail] = useState("");
    const [telefonoUsuario, setTelefono] = useState("");
    const [idTipoPerfil, setTipoPerfil] = useState(0);
    const [tipoPerfilesList, setTipoPerfiles] = useState([]);
    const [contrasenaUsuario, setContrasena] = useState("");
    const [confirmarContrasena, setConfirmar] = useState("");
    const [estado, setEstado] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        listarTipoPerfiles();
        getDatosUsuario();
        return () =>{
        }
    }, []);

    const limpiarCampos = () => {
        setRut("");
        setNombres("");
        setApellidos("");
        setTelefono("");
        setEmail("");
        setRut("");
      };
/*  Listo los tipos de Perfiles */
    const listarTipoPerfiles = async() =>{
        await Axios.get(`${config.apiUrl}/api/tipoPerfil`,{headers: {'Authorization': token,},})
        .then((response) => {setTipoPerfiles(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de Tipo de Cuentas Bancarias:", error.response);});
    };
/* Obtengo los datos del Cliente */
const getDatosUsuario = async () => {
    await Axios.get(`${config.apiUrl}/api/usuarios/` + id,{headers:{'Content-Type':'application/json','Authorization': token}})
    .then((response) => {
        setIdPersona(response.data.idPersona)
        setRut(response.data.rutUsuario)
        setNombres(response.data.nombreUsuario)
        setApellidos(response.data.apellidoUsuario)
        setEmail(response.data.emailUsuario)
        setTelefono(response.data.telefonoUsuario)
        setTipoPerfil(response.data.idTipoPerfil)
        setEstado(response.data.estadoUsuario)
    })
    .catch((error) => {
        console.error("Atención: Hubo un problema al recuperar los datos del Usuario.")
    });
};
/*  Fin */
    const editarUsuario = async() => {
        if (validaEdicionUsurio(rutUsuario, nombreUsuario, apellidosUsuario, telefonoUsuario, emailUsuario, idTipoPerfil, contrasenaUsuario, confirmarContrasena)){// Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaEdicionUsurio(rutUsuario, nombreUsuario, apellidosUsuario, telefonoUsuario, emailUsuario, idTipoPerfil, contrasenaUsuario, confirmarContrasena)
            SweetAlert(mensaje, "error","<strong>Atención</strong>") 
        }else{ 
            const datosUsuario = {
                rut: rutUsuario.toUpperCase(), nombres: nombreUsuario.toUpperCase(), apellidos: apellidosUsuario.toUpperCase(),
                telefono: telefonoUsuario, email: emailUsuario.toUpperCase(), idTipoPerfil: idTipoPerfil,
                estado: estado
            }
            await Axios.put(`${config.apiUrl}/api/usuarios/` + id,datosUsuario,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroActualizado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al editar el usuario.</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-address-book'></span>&nbsp;Editar Usuario</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="fname" name="name" type="text" placeholder="Nombres" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNombres(event.target.value);}} required maxLength={50} value={nombreUsuario}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'>
                            <input id="fname" name="name" type="text" placeholder="Apellidos" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setApellidos(event.target.value);}} required maxLength={50} value={apellidosUsuario}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setRut(event.target.value);}} maxLength={11} readOnly value={rutUsuario}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-envelope"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="email" name="email" type="text" placeholder="email@dominio.cl" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setEmail(event.target.value);}} maxLength={100} value={emailUsuario}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-phone-square"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="fname" name="name" type="text" placeholder="91235678" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setTelefono(event.target.value);}} maxLength={9} value={telefonoUsuario}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-user-plus"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setTipoPerfil(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" defaultValue={idTipoPerfil}>
                                <option value={0}>--SELECCIONE--</option>
                                {tipoPerfilesList.length > 0 ?
                                    tipoPerfilesList.map((val) => {
                                    return (
                                        <option value={val.idPerfil} key={val.idPerfil} selected={val.idPerfil === idTipoPerfil ? 'selected' : ''} defaultValue={idTipoPerfil}>{val.nombrePerfil}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-toggle-on"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <select className="textosSelect2 textosNormal" onChange={(event) => {setEstado(event.target.value);}}
                                    aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={8} id="idInsumo" defaultValue={estado}>
                                    <option value={true} selected={estado ? "selected" : ""}>Activo</option>
                                    <option value={false} selected={!estado ? "selected" : ""}>Inactivo</option>
                                </select> 
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                        </div>    
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={editarUsuario}><h5><span className="fa fa-refresh"></span></h5>&nbsp;Actualizar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default EditarUsuario