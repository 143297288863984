import React from "react";
import config from '../../config';
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";    
import Axios from "axios";
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import RegistrarMarca from "./RegistrarMarca";
import EditarMarca from './EditarMarca';

function ListadoMarcas(){
    const [marcasList, setMarcas] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [idMarca, setIdMarca] = useState(0);
    const [filtering, setFiltering] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const token = localStorage.getItem('token');
    const columnas = [
        {header: "Marca",accessorKey: 'nombreMarca'},
        {header: "Estado",
            accessorKey: 'estado',       
            cell:(fila)=>{
                return(
                    (fila.getValue('estado') ? 
                        <span className="textosNormal"><span className="fa fa-check-circle-o text-success"></span></span> : 
                        <span className="textosNormal"><span className="fa fa-times-circle text-danger"></span></span>
                    )
                )
            }
        },
        {
            header: "Acciones",
            accessorKey: 'idMarca',
            cell: (fila) => {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="transparent-button" onClick={()=>editarMarca(fila.getValue('idMarca'))} title="Editar">
                                        <span className="textos textosEditar"><span className="fa fa-pencil-square-o"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={()=>eliminarRegistro(fila.getValue('idMarca'))} title="Dar de baja">
                                    <span className="textos text-danger"><span className="fa fa-trash"></span></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
        },
    ]
    useEffect(() => {
        listarMarcas();
    }, []);
    const cerrarModal = ()=>{
        setShowModal(false);
        listarMarcas();
    }
    const cerrarModal2 = ()=>{
        setShowModal2(false);
        listarMarcas();
    }
    const registrarMarca = ()=>{
        setShowModal(true);
    }
    const editarMarca = (idMarca) =>{
        setIdMarca(idMarca)
        setShowModal2(true);
    }
/*  Doy de baja un registro */
    const eliminarRegistro = async(id) =>{
        Swal.fire({
            title: 'Atención!', text: "¿Está seguro de continuar?",
            icon: 'warning', showCancelButton: true,confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', confirmButtonText: 'Confirmar'
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarMarca(id);
            }
        });
    }
    const eliminarMarca = async (id) => {
        const datosMarca = {
            estado: 0
        }
        await Axios.put(`${config.apiUrl}/api/marcas/delete/` + id,datosMarca, { headers: { 'Content-Type': 'application/json', 'Authorization': token } })
            .then((response) => { listarMarcas() })
            .catch((error) => { console.error("Atención: ha ocurrido un error.", error.response); });
    }
/* Fin */
    const listarMarcas = async() =>{
        await Axios.get(`${config.apiUrl}/api/marcas/general`,{headers: {'Authorization': token,},})
        .then((response) => {setMarcas(response.data);})
        .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
    };
    const tabla = useReactTable(
        {
            data: marcasList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
                    <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
                    onChange={e=>setFiltering(e.target.value)}/>
                </div>
                <div className="col-sm-9 col-md-9 col-lg-9">
                    <button className='btn-agregar' onClick={registrarMarca}><span className='fa fa-plus-square'></span>&nbsp;Nueva</button>
                </div>
            </div>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                    {tabla.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <RegistrarMarca/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                <Modal.Body>
                    <EditarMarca id={idMarca}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal2()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListadoMarcas