import React from "react";
import config from '../../config'
import { useState, useEffect } from "react";    
import Axios from "axios";
import { Modal, ModalHeader } from 'react-bootstrap';
import ListadoInsumosStock from "../insumos/ListadoInsumosStock";

function InsumosStock(){
    const [getStock, setStock] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem('token');
    useEffect(() => {
        stockInsumos();
        modalStock();
    }, []);
    const cerrarModal = ()=>{
        setShowModal(false);
    }
    const modalStock = ()=>{
        if (getStock < 9) {
            setShowModal(true);
        }
    }
    const stockInsumos = async() =>{
        await Axios.get(`${config.apiUrl}/api/datosDashBoard/insumos/`,{headers: {'Authorization': token,},})
        .then((response) => {setStock(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener el conteo de insumos:", error.response);});
    };
    return(
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
                {getStock.map((val) => {
                    return (
                        val.totalRegistros != 0 ? 
                            <div className="row">
                                <div className="col-sm-3 col-md-3 col-lg-3"></div>
                                <div className="col-sm-6 col-md-6 col-lg-6">
                                    <div className="alert alert-danger">
                                        <h5><span className="text-danger"><strong>Atención:</strong> Existen {val.totalRegistros} insumos con bajo stock!</span></h5>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-3 col-lg-3"></div>
                            </div> : ""
                        );
                    })}
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <ModalHeader>
                    <span className="textos text-danger"><strong>Atención:</strong> Los siguientes insumos se encuentran con bajo Stock.</span>
                </ModalHeader>
                <Modal.Body>
                    <ListadoInsumosStock/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default InsumosStock