import React from 'react';
import config from '../../config';
import Axios from "axios";
import { useState, useEffect } from "react"; 
import SweetAlert from "../SweetAlert";
import { validaNotificacionEmail} from "../validaciones/Validaciones";

function NotificarEmail({id}) {
    const [nombreCliente, setNombreCliente] = useState('');
    const [emailCliente, setEmailCliente] = useState('');
    const [patenteVehiculo, setPatenteVehiculo] = useState('');
    const [proximaMantencion, setProximaMantención] = useState('1900-01-01');
    const [detalleVehiculo, setDetalleVehiculo] = useState('');
    const [tituloNotificacion, setTitulo] = useState('');
    const [mensajeNotificacion, setMensaje] = useState('');
    const [idTrabajo, setIdTrabajo] = useState('');
    const [permiteCambio,setPermiteCambio] = useState(false); //Lo utilizaremos para validar si la notificación fue enviada y con esto cambiamos el flag de notificado a true
    const token = localStorage.getItem('token');
    let titulo = "";
    let mensaje = "";
    let icono = "";
    useEffect(() => {
        getDatosTrabajo();
        getDatosNotificacion();
        return () =>{
        }
    }, []);
/*  Obtenemos los datos de la notificación */
const getDatosNotificacion = async () => {
    await Axios.get(`${config.apiUrl}/api/notificacion`,{headers:{'Content-Type':'application/json','Authorization': token}})
    .then((response) => {
        setTitulo(response.data.tituloNotificacion);
        setMensaje(response.data.detalleNotificacion);
    })
    .catch((error) => {
        console.error("Atención: Hubo un problema al recuperar los datos de  la notificación.")
    });
};
/*  Obtenemos los datos del trabajo */
const getDatosTrabajo = async () => {
    await Axios.get(`${config.apiUrl}/api/trabajos/` + id,{headers:{'Content-Type':'application/json','Authorization': token}})
    .then((response) => {
        setNombreCliente(response.data.nombreCliente)
        setEmailCliente(response.data.emailCliente)
        setProximaMantención(response.data.fechaProxMantencion)
        setDetalleVehiculo(response.data.detalleVehiculo)
        setPatenteVehiculo(response.data.patenteVehiculo)
        setIdTrabajo(response.data.idTrabajo)
    })
    .catch((error) => {
        console.error("Atención: Hubo un problema al recuperar los datos del cliente.")
    });
};
/* Realizamos el envío de email */
    const cambiaEstadoNotificado = async()=>{
        const datosTrabajo = {
            notificado: 1,
        }
        await Axios.put(`${config.apiUrl}/api/trabajos/notificado/` + idTrabajo,datosTrabajo,{headers:{'Content-Type':'application/json','Authorization': token}})
        .then((response) => {
            console.log(response.data)
        })
        .catch((error) => {
            console.log("fracaso")
        });
    }
/* Fin */

const enviarNotificacion = async() => { // Enviamos la Notificación al cliente
    if (validaNotificacionEmail(tituloNotificacion, mensajeNotificacion)){ // Validamos los datos
        titulo = "<strong>Atención</strong>";
        icono = "error";
        mensaje = validaNotificacionEmail(tituloNotificacion, mensajeNotificacion)
        SweetAlert(mensaje, "error","<strong>Atención</strong>")
    }else{
        let mensajeNotificar = mensajeNotificacion.replace("nombreCliente",nombreCliente)
            mensajeNotificar = mensajeNotificar.replace("detalleVehiculo",detalleVehiculo)
            mensajeNotificar = mensajeNotificar.replace("nroPatente",patenteVehiculo)
            mensajeNotificar = mensajeNotificar.replace("fechaMatención",proximaMantencion)
        const datosNotificacion = {
            emailDestinatario: emailCliente,
            asuntoEmail: tituloNotificacion,
            cuerpoEmail: mensajeNotificar,
        }
        await Axios.post(`${config.apiUrl}/api/enviarMail`,datosNotificacion,{headers:{'Content-Type':'application/json','Authorization': token},timeout: 5000})
        .then((response) => {
            titulo = "<strong>Atención</strong>";
            mensaje = response.data.message;
            icono = response.data.emailEnviado ? "success" : "error";
            SweetAlert(mensaje, icono,titulo)
            cambiaEstadoNotificado()
        })
        .catch((error) => {
            titulo = "<strong>Atención</strong>";
            mensaje =  "<i>Atención: Hubo un problema al enviar la notificación.</i>";
            icono = "error";
            SweetAlert(mensaje, icono,titulo);
        });
    }
};
/* Fin */

    return (
        <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-envelope'></span>&nbsp;Notificar Usuario</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-user"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            {nombreCliente}
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-car"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            {detalleVehiculo}
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            {patenteVehiculo}
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={enviarNotificacion}><h5><span className="fa fa-paper-plane"></span></h5>&nbsp;Notificar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default NotificarEmail;