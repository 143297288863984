import React from "react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import * as XLSX from "xlsx"

function ExportarExcel({datosReporte}){
    const [loading, setLoading] = useState(false);
    const handleDownload = () =>{
        setLoading(true);
        const libro = XLSX.utils.book_new();
        const hoja = XLSX.utils.json_to_sheet(datosReporte);
        XLSX.utils.book_append_sheet(libro,hoja,"Reporte");
        setTimeout(()=>{
            XLSX.writeFile(libro,"ReporteTaller.xlsx");
            setLoading(false);
        },1000)
    };
    return(
        <>
            {!loading ? 
                (<button className="btn-agregar" onClick={handleDownload}><span className="fa fa-file-excel-o"></span>&nbsp;Exportar Excel</button>) :
                (<button className="btn-agregar" disabled><spinner size="sm">Cargando...</spinner>
                    <span>Generando...</span>
                </button>)
            }
        </>
    );
} 
export default ExportarExcel;