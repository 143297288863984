import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import Swal from "sweetalert2";
import SweetAlert from "../SweetAlert";
import { validaTrabajo} from "../validaciones/Validaciones";

function RegistrarTrabajo(){
    const [detalleTrabajo, setDetalle] = useState("");
    const [fechaTrabajo, setFechaTrabajo] = useState("");
    const [proxMantencion, setProxMantencion] = useState("");
    const [requiereNotificacion, setNotificacion] = useState(false);
    const [manoObra, setManoObra] = useState("");
    const [idVehiculo, setIdVehiculo] = useState("");
    const [vehiculosList, setVehiculos] = useState([]);
    const [mecanicosList, setMecanicos] = useState([]);
    const [idEstadoTrabajo, setIdEstadoTrabajo] = useState(1);
    const [estadosList,setEstados] = useState([]);
    const [idPerfilMecanico, setIdPerfilMecanico] = useState(0);
    const [observacionesTrabajo, setObservaciones] = useState("");
    const token = localStorage.getItem('token');
    const idTipoPerfil = localStorage.getItem('idTipoPerfil');
    const idPerfil = localStorage.getItem('idPerfil');
    const [show, setShow] = useState(false);
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        listarVehiculos();
        listarMecanicos();
        listarEstados();
    }, []);

    const limpiarCampos = () => {
        setDetalle("")
        setFechaTrabajo("1900-01-01")
        setProxMantencion("1900-01-01")
        setNotificacion(false)
        setManoObra("")
        setIdVehiculo(0)
        setIdEstadoTrabajo(1)
        setObservaciones("")
      };
/*  Obtengo un listado de los Vehiculos con sus respectivas patentes */
const listarMecanicos = async() =>{
    await Axios.get(`${config.apiUrl}/api/usuarios/activos/`,{headers: {'Authorization': token,},})
    .then((response) => {setMecanicos(response.data); console.log(response.data)})
    .catch((error) => {console.error("Hubo un error al obtener la lista de mecanicos:", error.response);});
};
/*  Obtengo un listado de los Vehiculos con sus respectivas patentes */
const listarVehiculos = async() =>{
    await Axios.get(`${config.apiUrl}/api/vehiculos/general/`,{headers: {'Authorization': token,},})
    .then((response) => {setVehiculos(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de vehiculos:", error.response);});
};
/*  Obtengo un listado de los estados por los que puede pasar un trabajo */
const listarEstados = async() =>{
    await Axios.get(`${config.apiUrl}/api/estadoTrabajos`,{headers: {'Authorization': token,},})
    .then((response) => {setEstados(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de de Estados:", error.response);});
};
/* Busco el id. del vehiculo en base a la patente */
const buscarVehiculo = (nroPatente) =>{
    Axios.get(`${config.apiUrl}/api/vehiculos/general/` + nroPatente,{headers: {'Authorization': token,},})
    .then((response) => {
        setIdVehiculo(response.data.idVehiculo)
        console.log(response.data.idVehiculo)
    })
    .catch((error) => {console.error("Hubo un error al obtener los datos del vehiculo:", error.response);});
};
/* REgistro el trabajo */
    const registrarTrabajo = async() => {
        if (validaTrabajo(detalleTrabajo, fechaTrabajo, proxMantencion, requiereNotificacion, manoObra, idVehiculo, idEstadoTrabajo, observacionesTrabajo)){// Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaTrabajo(detalleTrabajo, fechaTrabajo, proxMantencion, requiereNotificacion, manoObra, idVehiculo, idEstadoTrabajo, observacionesTrabajo)
            SweetAlert(mensaje, "error","<strong>Atención</strong>") 
        }else{
            const datosTrabajo = {
                descripcionTrabajo: detalleTrabajo.toUpperCase(), fechaTrabajo: fechaTrabajo, proximaMantencion: (proxMantencion === '' ? '1900-01-01' : proxMantencion),
                requiereNotificacion: requiereNotificacion, costoManoObra: manoObra, idVehiculo: idVehiculo, idEstadoTrabajo: idEstadoTrabajo, idPerfil: (idPerfilMecanico !== 0 ? idPerfilMecanico : idPerfil),
                observacionTrabajo: (observacionesTrabajo !== '' ? observacionesTrabajo.toUpperCase() : ''),
            }
            await Axios.post(`${config.apiUrl}/api/trabajos`,datosTrabajo,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                limpiarCampos();
                setShow(false);
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el trabajo</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-wrench'></span>&nbsp;Nuevo Trabajo</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-book"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="detalle" name="detalle" type="text" placeholder="Descripción Trabajo" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setDetalle(event.target.value);}} required />
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-calendar"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="fechaTrabajo" name="fechaTrabajo" type="date" placeholder="Fecha Trabajo" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setFechaTrabajo(event.target.value);}} required/>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <span className="textosNormal"><small>Fecha Trabajo</small></span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-calendar"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="proxMantencion" name="proxMantencion" type="date" placeholder="Fecha Trabajo" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setProxMantencion(event.target.value);}} required/>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <span className="textosNormal"><small>Prox. Mantención</small></span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-envelope"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input type="checkbox" name="requiereNotificacion" id="requiereNotificacion" value={true} className="textosNormal" 
                            onChange={(event) => {setNotificacion(event.target.value);}}/>
                            <span className="textosNormal"><small>Req. Notificación</small></span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-car"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="patente" name="patente" type="text" placeholder="Patente" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {buscarVehiculo(event.target.value);}} maxLength={6} list="patentes"/>
                            <datalist id="patentes">
                                {vehiculosList.length > 0 ?
                                    vehiculosList.map((val) => {
                                    return (
                                        <option value={val.nroPatente} key={val.nroPatente}>{val.nroPatente}</option>
                                    );
                                }) : ''}
                            </datalist>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <span className="textosNormal"><small>Ej.: GKSB78</small></span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-money"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="manoObra" name="manoObra" type="number" placeholder="Costo Mano Obra" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setManoObra(event.target.value);}} maxLength={6}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-pencil-square"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <textarea placeholder="Observaciones" className="textosCajasArea textosNormal text-uppercase" maxLength={250}
                            onChange={(event) => {setObservaciones(event.target.value);}}/>
                        </div>
                    </div>
                    {idTipoPerfil == 1 ?
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-wrench"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setIdPerfilMecanico(event.target.value);}}
                                        aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={3}>
                                        <option value="">--SELECCIONE--</option>
                                        {mecanicosList.length > 0 ?
                                            mecanicosList.map((val) => {
                                            return (
                                                <option value={val.idPerfil} key={val.idPerfil}>{val.nombreCompletoUsuario}</option>
                                            );
                                        }) : ''}
                                    </select>                           
                            </div>
                        </div>
                    : ''}
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-wrench"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <select className="textosSelect textosNormal" onChange={(event) => {setIdEstadoTrabajo(event.target.value);}}
                                    aria-label="Dropdown" aria-describedby="select-addon1" defaultValue={1}>
                                    {estadosList.length > 0 ?
                                        estadosList.map((val) => {
                                        return (
                                            <option value={val.idEstadoTrabajo} key={val.idEstadoTrabajo} 
                                            selected={val.idEstadoTrabajo === idEstadoTrabajo ? 'selected' : ''}>{val.estadoTrabajo}</option>
                                        );
                                    }) : ''}
                                </select>    
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={registrarTrabajo}><span className="fa fa-floppy-o"></span>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarTrabajo