import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
const meta = document.createElement('meta');
meta.httpEquiv = 'Content-Security-Policy';
document.head.appendChild(meta);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

