import React from "react";
import config from '../../config'
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import { validarCliente } from "../validaciones/Validaciones";

function EditarClientes({id}){
    const [idCliente, setId] = useState("");
    const [rutCliente, setRut] = useState("");
    const [nombresCliente, setNombres] = useState("");
    const [apellidosCliente, setApellidos] = useState("");
    const [emailCliente, setEmail] = useState("");
    const [telefonoCliente, setTelefono] = useState("");
    const [estadoCliente, setEstado] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        getDatosCliente();
        return () =>{
        }
    },[]);
    
    const getDatosCliente = async (event) => {
        await Axios.get(`${config.apiUrl}/api/clientes/` + id,{headers:{'Content-Type':'application/json','Authorization': token}})
        .then((response) => {
            setId(response.data.idCliente)
            setRut(response.data.rutCliente)
            setNombres(response.data.nombreCliente)
            setApellidos(response.data.apellidoCliente)
            setEmail(response.data.emailCliente)
            setTelefono(response.data.telefonoCliente)
            setEstado(response.data.estadoCliente)
        })
        .catch((error) => {
            console.error("Atención: Hubo un problema al recuperar los datos del cliente.")
        });
    };
    const editarCliente = async (event) =>{
        event.preventDefault();
        if (validarCliente(rutCliente, nombresCliente, apellidosCliente, emailCliente, telefonoCliente)){
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validarCliente(rutCliente, nombresCliente, apellidosCliente, emailCliente, telefonoCliente)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosCliente = {
                id: idCliente, rut: rutCliente.toUpperCase(), nombres: nombresCliente.toUpperCase(), apellidos: apellidosCliente.toUpperCase(),
                telefono: telefonoCliente, email: emailCliente.toUpperCase(), estado: estadoCliente
            }
            await Axios.put(`${config.apiUrl}/api/clientes/` + id,datosCliente,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroActualizado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo);
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el cliente</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    }
    return(
        <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-address-book'></span>&nbsp;Editar Cliente</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="fname" name="name" type="text" placeholder="Nombres" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNombres(event.target.value);}} required maxLength={30} value={nombresCliente} tabIndex={1}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'>
                            <input id="fname" name="name" type="text" placeholder="Apellidos" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setApellidos(event.target.value);}} required maxLength={30} value={apellidosCliente} tabIndex={2}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setRut(event.target.value);}} maxLength={11} value={rutCliente} tabIndex={3}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-envelope"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="email" name="email" type="text" placeholder="email@dominio.cl" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setEmail(event.target.value);}} maxLength={30} value={emailCliente} tabIndex={4}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-phone-square"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="fname" name="name" type="text" placeholder="912345678" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setTelefono(event.target.value);}} maxLength={9} value={telefonoCliente} tabIndex={5}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-toggle-on"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <select className="textosSelect2 textosNormal" onChange={(event) => {setEstado(event.target.value);}}
                                    aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={8} id="idInsumo">
                                    <option value="true" selected={estadoCliente ? "selected" : ""}>Activo</option>
                                    <option value="false" selected={!estadoCliente ? "selected" : ""}>Inactivo</option>
                                </select> 
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                        </div>    
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-lg-center">
                            <button type="submit" className="btn-agregar" onClick={editarCliente} tabIndex={6}>
                                <span className="textosNormal"><span className="fa fa-refresh"></span>&nbsp;Actualizar</span>
                            </button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default EditarClientes