import React from "react";
import config from '../../config';
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";    
import { Modal } from 'react-bootstrap';
import EditarInsumo from "./EditarInsumo";
import Axios from "axios";
import Swal from 'sweetalert2';
import SweetAlert from "../SweetAlert";

function ListadoInsumos({showList}){
    const [insumosList, setInsumos] = useState([]);
    const [cantidadInsumos, setCantidadInsumos] = useState(0);
    const [filtering, setFiltering] = useState("");
    const [sorting, setSorting] = useState([]);
    const [idInsumo, setIdInsumo] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""
    const columnas = [
        {header: "Nombre",accessorKey: 'nombreInsumo'},
        {header: "Código",accessorKey: 'codigoInsumo'},
        {header: "Stock",
            accessorKey: 'cantidadInsumos',
            cell:(fila)=>{
                return(
                    (fila.getValue('cantidadInsumos') < 10 ? 
                        <span className="text-danger">{fila.getValue('cantidadInsumos')}</span> : 
                        <span className="text-success">{fila.getValue('cantidadInsumos')}</span>
                    )
                )
            }
        },
        {header: "Precio Compra",accessorKey: 'precioCompra'},
        {header: "Precio Venta",accessorKey: 'precioVenta'},
        {header: "Tipo",accessorKey: 'tipoInsumo'},
        {header: "Estado",
            accessorKey: 'estadoInsumo',
            cell:(fila)=>{
                return(
                    (fila.getValue('estadoInsumo') ? 
                        <span className="textosNormal"><span className="fa fa-check-circle-o text-success"></span></span> : 
                        <span className="textosNormal"><span className="fa fa-times-circle-o text-danger"></span></span>
                    )
                )
            }
        },
        {header: "Acciones",
            accessorKey: 'id',
            cell: (fila) => {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="transparent-button" onClick={() => editarInsumo(fila.getValue('id'))} title="Editar">
                                        <span className="textos textosEditar"><span className="fa fa-pencil-square-o"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={()=>eliminarRegistro(fila.getValue('id'))} title="Dar de baja">
                                    <span className="textos text-danger"><span className="fa fa-trash"></span></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
        },
    ]
    useEffect(() => {
        listarInsumos();
    }, [showList]);
    const cerrarModal = ()=>{
        listarInsumos();
        setShowModal(false);
    }
    const editarInsumo = async(idInsumo)=>{
        setIdInsumo(idInsumo);
        setShowModal(true);
    }
/*  Doy de baja un registro */
const eliminarRegistro = async(id) =>{
    let cantidad = 0;
    await Axios.get(`${config.apiUrl}/api/insumos/` + id,{headers: {'Authorization': token,},})
    .then((response) => { cantidad =  response.data.cantidadInsumos;})
    .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
    if (cantidad == 0){
        Swal.fire({
            title: 'Atención!', text: "¿Está seguro de continuar?",
            icon: 'warning', showCancelButton: true,confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33', confirmButtonText: 'Confirmar'
        }).then((result) => {
            if (result.isConfirmed) {
                eliminarInsumo(id);
            }
        });
    }else{
        titulo = "<strong>Atención</strong>";
        mensaje = "No se puede dar de baja un insumo con stock mayor a 0."
        icono = "error";
        SweetAlert(mensaje, icono,titulo);
    }
}
const eliminarInsumo = async (id) => {
    const datosRegistro = {
        estado: 0
    }
    await Axios.put(`${config.apiUrl}/api/insumos/delete/` + id,datosRegistro, { headers: { 'Content-Type': 'application/json', 'Authorization': token } })
        .then((response) => { listarInsumos() })
        .catch((error) => { console.error("Atención: ha ocurrido un error.", error.response); });
}
/* Fin */
    const listarInsumos = async() =>{
        await Axios.get(`${config.apiUrl}/api/insumos/general`,{headers: {'Authorization': token,},})
        .then((response) => {setInsumos(response.data);})
        .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
    };

    const tabla = useReactTable(
        {
            data: insumosList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
            <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
            onChange={e=>setFiltering(e.target.value)}/>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id} className="textos">
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                {tabla.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
                </tbody>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <EditarInsumo id={idInsumo}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListadoInsumos