import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";  
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";  
import Axios from "axios";
import Swal from "sweetalert2";
import SweetAlert from "../SweetAlert";
import { validaAsociacionInsumo} from "../validaciones/Validaciones";

function InsumoTrabajo({id}){
    const [idTipoInsumo,setIdTipoInsumo] = useState(0);
    const [idInsumo, setIdInsumo] = useState(0);
    const [datoCantidadInsumo, setDatoCantidadInsumo] = useState(0);
    const [cantidadInsumos, setCantidadInsumos] = useState(0);
    const [insumosTrabajoList, setInsumosTrabajo] = useState([]);
    const [tipoInsumosList, setTipoInsumos] = useState([]);
    const [insumosList, setInsumos] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState("");
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""
    const columnas = [
        {header: "Tipo", accessorKey: 'tipoInsumo'},
        {header: "Insumo", accessorKey: 'nombreInsumo'},
        {header: "Cant.",accessorKey: 'cantidadInsumos'},
        {header: "$",accessorKey: 'costoInsumos'},
        {
            header: "Elim.",
            accessorKey: 'idDetalleTrabjo',
            cell: (fila) => {
                return (
                    <button className="transparent-button" tabIndex={5} onClick={()=>eliminarRegistro(fila.getValue('idDetalleTrabjo'))} title="Eliminar">
                        <span className="textosNormal text-danger"><span className="fa fa-trash"></span></span>
                    </button>
                );
            },
        },
    ]
    useEffect(() => {
        listarInsumosTrabajo();
        listarTipoInsumos();
    }, []);
/*  Listo los insumos asociados al trabajo */
    const listarInsumosTrabajo = async() =>{
        await Axios.get(`${config.apiUrl}/api/trabajos/detalleTrabajos/` + id,{headers: {'Authorization': token,},})
        .then((response) => {setInsumosTrabajo(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de usuarios:", error.response);});
    };
/*  Fin */
/* Listo los tipos de insumos existentes */
const listarTipoInsumos = async() =>{
    await Axios.get(`${config.apiUrl}/api/tipoInsumos`,{headers: {'Authorization': token,},})
    .then((response) => {setTipoInsumos(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de tipos de Insumos:", error.response);});
};
/* Creo una función para obtener los insumos en base a un tipo */
    const listarInsumos = async(idTipoInsumo) =>{
        await Axios.get(`${config.apiUrl}/api/insumos/tipo/` + idTipoInsumo,{headers: {'Authorization': token,},})
        .then((response) => {setInsumos(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de Insumos:", error.response);});
    };
/* Obtengo los datos de un insumo */
    const obtieneDatosInsumo = async(idInsumo) =>{
        setIdInsumo(idInsumo);
        await Axios.get(`${config.apiUrl}/api/insumos/` + idInsumo,{headers: {'Authorization': token,},})
        .then((response) => {setDatoCantidadInsumo(response.data.cantidadInsumos)})
        .catch((error) => {console.error("Hubo un error al obtener la lista de Insumos:", error.response);});
    };
/* Registro los datos del Insumo */
const registrarInsumoTrabajo = async() => {
    if (validaAsociacionInsumo(idInsumo,datoCantidadInsumo, cantidadInsumos)){ // Validamos los datos
        titulo = "<strong>Atención</strong>";
        icono = "error";
        mensaje = validaAsociacionInsumo(idInsumo,datoCantidadInsumo, cantidadInsumos)
        SweetAlert(mensaje, "error","<strong>Atención</strong>")
    }else{    
        const datosInsumoTrabajo = {
            idInsumo: idInsumo, idTrabajo: id, cantidadInsumos: cantidadInsumos
        }
        await Axios.post(`${config.apiUrl}/api/trabajos/detalleTrabajos`,datosInsumoTrabajo,{headers:{'Content-Type':'application/json','Authorization': token}})
        .then((response) => {
            listarInsumosTrabajo();
            titulo = "<strong>Atención</strong>";
            mensaje = response.data.message;
            icono = response.data.registroCreado ? "success" : "error";
            SweetAlert(mensaje, icono,titulo)
        })
        .catch((error) => {
            titulo = "<strong>Atención</strong>";
            mensaje =  "<i>Atención: Hubo un problema al registrar la asciación de insumos.</i>";
            icono = "error";
            SweetAlert(mensaje, icono,titulo);
        });
    }
};
/* Fin */
/*  Doy de baja un registro */
const eliminarRegistro = async(id) =>{
    Swal.fire({
        title: 'Atención!', text: "¿Está seguro de continuar?",
        icon: 'warning', showCancelButton: true,confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', confirmButtonText: 'Confirmar'
    }).then((result) => {
        if (result.isConfirmed) {
            eliminarDetalle(id);
        }
    });
}
const eliminarDetalle = async (id) => {
    await Axios.delete(`${config.apiUrl}/api/trabajos/detalleTrabajos/delete/` + id,{headers: {'Authorization': token,},})
        .then((response) => { listarInsumosTrabajo() })
        .catch((error) => { console.error("Atención: ha ocurrido un error.", error.response); });
}
/* Fin */
    const tabla = useReactTable(
        {
            data: insumosTrabajoList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-cubes'></span>&nbsp;Asociar Insumos</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-barcode"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal" onChange={(event) => {listarInsumos(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={1}>
                                <option value="">--SELECCIONE--</option>
                                {tipoInsumosList.map((val) => {
                                    return (
                                        <option value={val.id} key={val.id}>{val.descripcion}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-info-circle"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal" onChange={(event) => {obtieneDatosInsumo(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={2}>
                                <option value="">--SELECCIONE--</option>
                                {insumosList.length > 0 ?
                                    insumosList.map((val) => {
                                    return (
                                        <option value={val.idInsumo} key={val.idInsumo}>{val.nombreInsumo}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-flask"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input type="number" className="textosCajas textosNormal" placeholder="Cantidad" min={0} max={datoCantidadInsumo} tabIndex={3} onChange={(event) => {setCantidadInsumos(event.target.value);}} /><span className="textosNormal">&nbsp;Max. {datoCantidadInsumo}</span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar"  tabIndex={4} onClick={()=>registrarInsumoTrabajo()}><h5><span className="fa fa-save"></span></h5>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 espaciadoVertical"></div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <table className="table table-hover table-responsive-lg"> 
                            <thead>
                                {
                                tabla.getHeaderGroups().map(headerGroup=> (
                                        <tr key={headerGroup.id} className="textosNormal">
                                            {
                                                headerGroup.headers.map(header=>(
                                                    <th key={header.id} 
                                                        onClick={header.column.getToggleSortingHandler()}>
                                                        {
                                                            flexRender(header.column.columnDef.header, header.getContext())}
                                                            {
                                                            {
                                                                'asc' : "⬆", 'desc' : "⬇"
                                                            }[header.column.getIsSorted() ?? null]
                                                            }
                                                    </th>
                                                ))
                                            }
                                        </tr>
                                    )) 
                                }
                            </thead>
                            <tbody>
                                {tabla.getRowModel().rows.map(row => (
                                    <tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                        <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>
    )
}
export default InsumoTrabajo