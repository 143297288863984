import React from 'react';
import { Helmet } from 'react-helmet';

const HelmetHeader = () => {
    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="icon" href="../public/logo-login.png" />
            <title>Taller Mecánico Leonhardt ©2023</title>

            {/* Adding Anti-Clickjacking Header */}
            <meta http-equiv="Content-Security-Policy" content="frame-ancestors 'none';" />
        </Helmet>
    );
};

export default HelmetHeader;