import React from "react";
import config from '../../config';
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";    
import Axios from "axios";
import { Modal } from 'react-bootstrap';

function ListadoTipoCuentas(){
    const [tipoCuentasList, setTipocuentas] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [idMarca, setIdMarca] = useState(0);
    const [filtering, setFiltering] = useState("");
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem('token');
    const columnas = [
        {
            header: "Nombre",
            accessorKey: 'tipoCuenta'
        },
        {
            header: "Estado",
            accessorKey: 'estado',
            cell:(fila)=>{
                return(
                    (fila.getValue('estado') ? 
                        <span className="textosNormal"><span className="fa fa-check-circle-o text-success"></span></span> : 
                        <span className="textosNormal"><span className="fa fa-times-circle text-danger"></span></span>
                    )
                )
            }
        },
        /* Se deja para futuro
        {
            header: "Acciones",
            accessorKey: 'idTipoCuenta',
            cell: (fila) => {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="transparent-button">
                                    <span className="textosNormal text-danger"><span className="fa fa-trash"></span></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
        }, */
    ]
    useEffect(() => {
        listarTipoCuentas();
    }, []);
    const listarTipoCuentas = async() =>{
        await Axios.get(`${config.apiUrl}/api/tipoCuentas/general`,{headers: {'Authorization': token,},})
        .then((response) => {setTipocuentas(response.data);})
        .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
    };
    const tabla = useReactTable(
        {
            data: tipoCuentasList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
            <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
            onChange={e=>setFiltering(e.target.value)}/>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                    {tabla.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ListadoTipoCuentas