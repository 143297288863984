import React from 'react';
import '../styleesheets/Dashboard.css';
import { Accordion } from 'react-bootstrap';
import ReporteSemanal from './reportes/ReporteSemanal'
import ReporteMensual from './reportes/ReporteMensual'
import ReporteAnual from './reportes/reporteAnual';

function Reportes() {
    return (
        <main className='overflow-auto'>
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <h3><span className='fa fa-print'></span>&nbsp;Reportería</h3>
                        </div>
                        <div className='card-body'>
                            <Accordion>
                                    <Accordion.Item eventKey='0' className='card-header'>
                                        <Accordion.Header ><span className='textosNormal'><span className='fa fa-file-text'></span>&nbsp;Reporte Semanal</span></Accordion.Header>
                                        <Accordion.Body>
                                            <ReporteSemanal/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey='1' className='card-header'>
                                        <Accordion.Header><span className='textosNormal'><span className='fa fa-file-text'></span>&nbsp;Reporte Mensual</span></Accordion.Header>
                                        <Accordion.Body>
                                            <ReporteMensual/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey='2' className='card-header'>
                                        <Accordion.Header><span className='textosNormal'><span className='fa fa-file-text'></span>&nbsp;Reporte Anual</span></Accordion.Header>
                                        <Accordion.Body>
                                            <ReporteAnual/>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
    
}

export default Reportes;