import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import { validaTipoInsumo} from "../validaciones/Validaciones";

function EditarTipoInsumo({id}){
    const [nombreTipoInsumo, setTipoInsumo] = useState("");
    const [estado, setEstado] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""
    useEffect(() => {
        obtenerDatoTipoInsumo();
    }, []);
/* Obtenemos los datos del tipo de insumo */
const obtenerDatoTipoInsumo = async()=>{
    await Axios.get(`${config.apiUrl}/api/tipoInsumos/` + id,{headers: {'Authorization': token,},})
    .then((response) => {
        setTipoInsumo(response.data.tipoInsumo);
        setEstado(response.data.estado);
    })
    .catch((error) => {console.error("Hubo un error al obtener proveedores:", error.response);});
}

/* Registramos nuestro Tipo de Insumo */
    const editarTipoInsumo = async() => {
        if (validaTipoInsumo(nombreTipoInsumo)){
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaTipoInsumo(nombreTipoInsumo)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosTipoInsumo = {
                nombre: nombreTipoInsumo.toUpperCase(),estado: estado
            }
            await Axios.put(`${config.apiUrl}/api/tipoInsumos/` + id,datosTipoInsumo,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroActualizado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el tipo de insumo.</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-flask'></span>&nbsp;Editar Tipo de Insumo</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-info-circle"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="fname" name="name" type="text" placeholder="Descripción" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setTipoInsumo(event.target.value);}} required defaultValue={nombreTipoInsumo}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-info-circle"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                        <select className="textosSelect textosNormal" onChange={(event) => {setEstado(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1">
                                <option value={true} key={1} selected={estado ? 'selected' : ''} >Activo</option>
                                <option value={false} key={2} selected={!estado ? 'selected' : ''} >Inactivo</option>
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={editarTipoInsumo}><span className="fa fa-refresh"></span>&nbsp;Actualizar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default EditarTipoInsumo