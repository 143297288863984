import React from "react";
import config from '../../config'
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import {validaVehiculo} from '../validaciones/Validaciones'

function EditarVehiculo({id}){
    const [nroPatente,setNroPatente] = useState("");
    const [idCliente,setIdCliente] = useState("");
    const [rutCliente, setRutCliente] = useState("");
    const [idMarca, setIdMarca] = useState("")
    const [idModelo,setIdModelo] = useState(0);
    const [nombreCliente, setNombreCliente] = useState("");
    const [estadoVehiculo, setEstado] = useState(false);
    const [marcasList, setMarcas] = useState([]);
    const [modelosList, setModelos] = useState([]);
    const [clientesList, setClientes] = useState([]);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        getDatosVehiculo(id);
        listarMarcas();
        listarClientes();
        return () =>{
        }
    },[]);
/*  Listamos las marcas de vehiculos habilitadas */
    const listarMarcas = async() =>{
        await Axios.get(`${config.apiUrl}/api/marcas/`,{headers: {'Authorization': token,},})
        .then((response) => {setMarcas(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de marcas:", error.response);});
};
/* Listamos los modelos de vehículos */
    const listarModelos = async(idMarca) =>{
        await Axios.get(`${config.apiUrl}/api/modelos/marca/` + idMarca,{headers: {'Authorization': token,},})
        .then((response) => {setModelos(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de marcas:", error.response);});
    };
/* Listamos los clientes */
    const listarClientes = async() =>{
        await Axios.get(`${config.apiUrl}/api/clientes`,{headers: {'Authorization': token,},})
        .then((response) => {setClientes(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de clientes:", error.response);});
    };
/* Obtenemos los datos de un cliente por rut */
    const obtenerDatosCliente = async(rutCliente) =>{
        await Axios.get(`${config.apiUrl}/api/clientes/general/` + rutCliente,{headers: {'Authorization': token,},})
        .then((response) => {
            setIdCliente(response.data.idCliente);
            setNombreCliente(response.data.nombreCompletoCliente);
        })
        .catch((error) => {console.error("Hubo un error al obtener los datos del cliente:", error.response);});
    };
/* Obtenemos los datos de un vehiculo */
const getDatosVehiculo = async () => {
    await Axios.get(`${config.apiUrl}/api/vehiculos/` + id,{headers:{'Content-Type':'application/json','Authorization': token}})
    .then((response) => {
        setNroPatente(response.data.nroPatente);
        setIdCliente(response.data.idDueno);
        setRutCliente(response.data.rutDueno);
        setNombreCliente(response.data.nombreDueno);
        setIdMarca(response.data.idMarca);
        setIdModelo(response.data.idModelo);
        setEstado(response.data.estadoVehiculo);
        listarModelos(response.data.idMarca);
    })
    .catch((error) => {
        console.error("Atención: Hubo un problema al recuperar los datos del Vehículo.")
    });
};
/* Fin */
    const editarVehiculo = async () =>{
        if (validaVehiculo(nroPatente, idCliente, idModelo)){ // Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaVehiculo(nroPatente, idCliente, idModelo)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosVehiculo = {
                patenteVehiculo: nroPatente.toUpperCase(),idPersona: idCliente,idModelo: idModelo,
                estado: estadoVehiculo
            }
            await Axios.put(`${config.apiUrl}/api/vehiculos/` + id,datosVehiculo,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroActualizado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo);
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al editar el Vehículo</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    }
    return(
        <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-car'></span>&nbsp;Editar Vehiculo</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-car"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {listarModelos(event.target.value)}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={2} defaultValue={idMarca}>
                                <option value={0}>--SELECCIONE--</option>
                                {marcasList.length > 0 ?
                                    marcasList.map((val) => {
                                    return (
                                        <option value={val.idMarca} key={val.idMarca} selected={val.idMarca === idMarca ? "selected" : ""}>{val.nombreMarca}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-cog"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setIdModelo(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={3} defaultValue={idModelo}>
                                <option value={0}>--SELECCIONE--</option>
                                {modelosList.length > 0 ?
                                    modelosList.map((val) => {
                                    return (
                                        <option value={val.idModelo} key={val.idModelo} selected={val.idModelo===idModelo ? "selected" : ""}>{val.nombreModelo}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-address-card"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="nroPatente" name="nroPatente" type="text" placeholder="Nro. Patente" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNroPatente(event.target.value);}} required maxLength={6} tabIndex={3} value={nroPatente} readOnly/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'>
                            <span className="textosNormal">Ej: GKSB78</span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut Cliente" className="textosCajas textosNormal text-uppercase"
                            maxLength={11} tabIndex={4} list="clientes" onChange={(event) => {obtenerDatosCliente(event.target.value)}} defaultValue={rutCliente}/>
                            <datalist id="clientes" defaultValue={rutCliente}>
                                {clientesList.length > 0 ?
                                    clientesList.map((val) => {
                                    return (
                                        <option value={val.rutCliente} key={val.idCliente} selected={val.rutCliente === rutCliente ? "selected" : ""}>{val.rutCliente}</option>
                                    );
                                }) : ''}
                            </datalist>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-user"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <span className="textosNormal">{nombreCliente}</span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-toggle-on"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect2 textosNormal" onChange={(event) => {setEstado(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1">
                                <option value={true} key={1} selected={estadoVehiculo ? 'selected' : ''}>Activo</option>
                                <option value={false} key={2} selected={!estadoVehiculo ? 'selected' : ''}>Inactivo</option>
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-lg-center">
                            <button type="submit" className="btn-agregar" onClick={()=>{editarVehiculo()}} tabIndex={5}>
                                <span className="textosNormal"><span className="fa fa-refresh"></span>&nbsp;Actualizar</span>
                            </button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default EditarVehiculo