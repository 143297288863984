import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";  
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";  
import Axios from "axios";

function DetalleInsumoTrabajo({id}){
    const [insumosTrabajoList, setInsumosTrabajo] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState("");
    const token = localStorage.getItem('token');
    const columnas = [
        {header: "Tipo", accessorKey: 'tipoInsumo'},
        {header: "Insumo", accessorKey: 'nombreInsumo'},
        {header: "Cant.",accessorKey: 'cantidadInsumos'},
        {header: "$",accessorKey: 'costoInsumos'},
        {
            header: "",
            accessorKey: 'idDetalleTrabjo',
            cell: (fila) => {
                return (
                    null
                );
            },
        },
    ]
    useEffect(() => {
        listarInsumosTrabajo();
    }, []);
/*  Listo los insumos asociados al trabajo */
    const listarInsumosTrabajo = async() =>{
        await Axios.get(`${config.apiUrl}/api/trabajos/detalleTrabajos/` + id,{headers: {'Authorization': token,},})
        .then((response) => {setInsumosTrabajo(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de usuarios:", error.response);});
    };
/*  Fin */
    const tabla = useReactTable(
        {
            data: insumosTrabajoList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
                <table className="table table-hover table-responsive-lg"> 
                    <thead>
                        {
                        tabla.getHeaderGroups().map(headerGroup=> (
                                <tr key={headerGroup.id} className="textosNormal">
                                    {
                                        headerGroup.headers.map(header=>(
                                            <th key={header.id} 
                                                onClick={header.column.getToggleSortingHandler()}>
                                                {
                                                    flexRender(header.column.columnDef.header, header.getContext())}
                                                    {
                                                    {
                                                        'asc' : "⬆", 'desc' : "⬇"
                                                    }[header.column.getIsSorted() ?? null]
                                                    }
                                            </th>
                                        ))
                                    }
                                </tr>
                            )) 
                        }
                    </thead>
                    <tbody>
                        {tabla.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </div>
    )
}
export default DetalleInsumoTrabajo