import React from "react";
import config from '../../config';
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";    
import Axios from "axios";
import EditarUsuario from "./EditarUsuario";
import CambioContrasenaUsuario from "./CambioContrasenaUsuario";
import Swal from 'sweetalert2';
import { Modal } from 'react-bootstrap';

function ListadoUsuarios({showList}){
    const [usuariosList, setUsuarios] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [idUsuario, setIdUsuario] = useState(0);
    const [filtering, setFiltering] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const token = localStorage.getItem('token');
    const columnas = [
        {header: "Nombre",accessorKey: 'nombreCompletoUsuario'},
        {header: "rut",accessorKey: 'rutUsuario'},
        {header: "Teléfono",accessorKey: 'telefonoUsuario'},
        {header: "Email",accessorKey: 'emailUsuario'},
        {header: "Tipo Perfil",accessorKey: 'tipoPerfil'},
        {header: "Estado",
            accessorKey: 'estadoUsuario',
            cell:(fila)=>{
                return(
                    (fila.getValue('estadoUsuario') ? 
                        <span className="textosNormal"><span className="fa fa-check-circle-o text-success"></span></span> : 
                        <span className="textosNormal"><span className="fa fa-times-circle text-danger"></span></span>
                    )
                )
            }
        },
        {header: "Acciones",
            accessorKey: 'idPersona',
            cell: (fila) => {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="transparent-button" onClick={() => editarUsuario(fila.getValue('idPersona'))} title="Editar">
                                        <span className="textos textosEditar"><span className="fa fa-pencil-square-o"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={() => cambiarContrasena(fila.getValue('idPersona'))} title="Cambiar contraseña">
                                        <span className="textos"><span className="fa fa-lock"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={()=>eliminarRegistro(fila.getValue('idPersona'))} title="Eliminar">
                                    <span className=" textos text-danger"><span className="fa fa-trash"></span></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
        },
    ]
    useEffect(() => {
        listarUsuarios();
    }, [showList]);
    const cerrarModal = ()=>{
        listarUsuarios();
        setShowModal(false);
    }
    const cerrarModal2 = ()=>{
        listarUsuarios();
        setShowModal2(false);
    }
    const editarUsuario = async(idUsuario)=>{
        setIdUsuario(idUsuario);
        setShowModal(true);
    }
    const cambiarContrasena = async(idUsuario)=>{
        setIdUsuario(idUsuario);
        setShowModal2(true);
    }
    const listarUsuarios = async() =>{
        await Axios.get(`${config.apiUrl}/api/usuarios`,{headers: {'Authorization': token,},})
        .then((response) => {setUsuarios(response.data);})
        .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
    };
/*  Doy de baja un registro */
const eliminarRegistro = async(id) =>{
    Swal.fire({
        title: 'Atención!', text: "¿Está seguro de continuar?",
        icon: 'warning', showCancelButton: true,confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', confirmButtonText: 'Confirmar'
    }).then((result) => {
        if (result.isConfirmed) {
            eliminarUsuario(id);
        }
    });
}
const eliminarUsuario = async (id) => {
    const datosRegistro = {
        estado: 0
    }
    await Axios.put(`${config.apiUrl}/api/usuarios/delete/` + id,datosRegistro, { headers: { 'Content-Type': 'application/json', 'Authorization': token } })
        .then((response) => { listarUsuarios() })
        .catch((error) => { console.error("Atención: ha ocurrido un error.", error.response); });
}
/* Fin */
    const tabla = useReactTable(
        {
            data: usuariosList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
            <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
            onChange={e=>setFiltering(e.target.value)}/>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                    {tabla.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <EditarUsuario id={idUsuario}/>
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
            <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <CambioContrasenaUsuario id={idUsuario}/>
                </Modal.Body>
                <Modal.Footer>
                <button className="btn btn-primary" onClick={()=>cerrarModal2()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListadoUsuarios