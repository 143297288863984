import React from 'react';
import Swal from 'sweetalert2';

const SweetAlert = (message, icono,titulo) => {
    Swal.fire({
        title: titulo,
        html: '<p style="text-align: jsutify;">' + message + '</p>',
        icon: icono,
        timer: 6000,
    }).then(() => {
        // Si se necesita alguna acción después de cerrar la alerta, se puede agregar aquí
    }).catch((error) => {
        console.error('Error en la alerta de SweetAlert:', error);
    });
};

export default SweetAlert;