import React from "react";
import config from '../../config';
import { jwtDecode } from 'jwt-decode';
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";  
import { Modal } from 'react-bootstrap';  
import Axios from "axios";
import EditarTrabajo from "./EditarTrabajo";
import InsumoTrabajo from "./InsumoTrabajo";
import DetalleTrabajo from './DetalleTrabajo'

function ListadoTrabajos({showList}){
    const [trabajosList, setTrabajos] = useState([]);
    const [datosTrabajo, setDatosTrabajo] = useState([]);
    const [idTrabajo, setIdTrabajo] = useState(0);
    const [sorting, setSorting] = useState([]);
    const [filtering, setFiltering] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal3, setShowModal3] = useState(false);
    const token = localStorage.getItem('token');
    const idTipoPerfil = localStorage.getItem('idTipoPerfil');
    const columnas = [
        {header: "Descripción", accessorKey: 'detalleTrabajo'},
        {header: "Fecha Trabajo", accessorKey: 'fechaTrabajo'},
        {header: "Prox. Mantención",
            accessorKey: 'fechaProxMantencion',
            cell:(fila)=>{
                return(
                    (fila.getValue('fechaProxMantencion') === '01-01-1900' ? "No Requiere" : fila.getValue('fechaProxMantencion'))
                )
            }
        },
        {header: "Req. Notificación",
            accessorKey: 'requiereNotificacion',
            cell:(fila)=>{
                return(
                    (fila.getValue('idTrabajo') ? "Si" : "NO")
                )
            }
        },
        {header: "Costo Trabajo",
            accessorKey: 'costoTotal',
            cell:(fila)=>{
                return(
                    ("$ " + fila.getValue('costoTotal'))
                )
            }
        },
        {header: "Mecánico", accessorKey: 'nombreMecanico'},
        {header: "Estado", accessorKey: 'estadoTrabajo'},
        {header: "Acciones",
            accessorKey: 'idTrabajo',
            cell: (fila) => {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="transparent-button" onClick={() => editarTrabajo(fila.getValue('idTrabajo'))} title="Editar">
                                        <span className="textos textosEditar"><span className="fa fa-pencil-square-o"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={() => asociarInsumo(fila.getValue('idTrabajo'))} title="Asociar Insumo">
                                        <span className="textos textos"><span className="fa fa-flask"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={() => detalleTrabajo(fila.getValue('idTrabajo'))} title="Ver detalle">
                                        <span className="textos textos"><span className="fa fa-eye"></span></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
        },
    ]
    useEffect(() => {
        listarTrabajos();
    }, [showList]);

    const cerrarModal = ()=>{
        listarTrabajos();
        setShowModal(false);
    }
    const cerrarModal2 = ()=>{
        listarTrabajos();
        setShowModal2(false);
    }
    const cerrarModal3 = ()=>{
        listarTrabajos();
        setShowModal3(false);
    }
    const editarTrabajo = async(idTrabajo)=>{
        setIdTrabajo(idTrabajo);
        setShowModal(true);
    }
    const detalleTrabajo = async(idTrabajo)=>{
        setIdTrabajo(idTrabajo);
        setShowModal3(true);
    }
    const asociarInsumo = async(idTrabajo)=>{
        setIdTrabajo(idTrabajo);
        setShowModal2(true);
    }
    const listarTrabajos = async() =>{
        {idTipoPerfil == 2 ?
            await Axios.get(`${config.apiUrl}/api/trabajos/listadoTrabajosMecanico/` + jwtDecode(token).idUsuario,{headers: {'Authorization': token,},})
            .then((response) => {setTrabajos(response.data);})
            .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);})
            : 
            await Axios.get(`${config.apiUrl}/api/trabajos/listadoTrabajosAdmin`,{headers: {'Authorization': token,},})
            .then((response) => {setTrabajos(response.data);})
            .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);})
        }
    };

    const tabla = useReactTable(
        {
            data: trabajosList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
            <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
            onChange={e=>setFiltering(e.target.value)}/>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id}>
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                {tabla.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
                </tbody>
                <tfoot>

                </tfoot>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <EditarTrabajo id={idTrabajo}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal2} onHide={() => setShowModal2(false)}>
                <Modal.Body>
                    <InsumoTrabajo id={idTrabajo}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal2()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal3} onHide={() => setShowModal3(false)}>
                <Modal.Body>
                    <DetalleTrabajo id={idTrabajo}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal3()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListadoTrabajos