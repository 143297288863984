import React from "react";
import config from '../../config';
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";    
import Axios from "axios";
import ExportarExcel from './ExportarExcel'


function ReporteSemanal(){
    const [datosReporte, setDatosReporte] = useState([]);
    const [dataTotal, setDataTotal] = useState([]);
    const [filtering, setFiltering] = useState("");
    const [sorting, setSorting] = useState([]);
    const token = localStorage.getItem('token');
    let  sumaTotal = 0;
    const columnas = [
        {header: "Id",accessorKey: 'id'},
        {header: "Fecha",accessorKey: 'fechaTrabajo'},
        {header: "Mecánico",accessorKey: 'nombreMecanico'},
        {header: "Trabajo",accessorKey: 'detalleTrabajo'},
        {header: "Observaciones",accessorKey: 'observacionTrabajo'},
        {header: "Marca",accessorKey: 'marcaVehiculo'}, 
        {header: "Modelo",accessorKey: 'modeloVehiculo'},
        {header: "Patente",accessorKey: 'patenteVehiculo'},
        {header: "Mano Obra",
            accessorKey: 'costoManoObra',
            cell:(fila)=>{
                return(
                    ("$ " + fila.getValue('costoManoObra'))
                )
            }
        },
        {header: "Cant. Insumos", accessorKey: 'totalInsumos'},
        {header: "valor Insumos", 
            accessorKey: 'valorInsumos',
            cell:(fila)=>{
                return(
                    ("$ " + fila.getValue('valorInsumos'))
                )
            }},
        {header: "Costo Total",
            accessorKey: 'totalTrabajo',
            cell:(fila)=>{
                return(
                    ("$ " + fila.getValue('totalTrabajo'))
                )
            }
        },
    ]
    useEffect(() => {
        generarReporte();
        totalReporte();
    }, []);
    const generarReporte = async() =>{
        await Axios.get(`${config.apiUrl}/api/reportes/semanal`,{headers: {'Authorization': token,},})
        .then((response) => {setDatosReporte(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la data.", error.response);});
    };
/*  Obtengo el total del reporte */
    const totalReporte = async() =>{
        await Axios.get(`${config.apiUrl}/api/reportes/totalSemanal`,{headers: {'Authorization': token,},})
        .then((response) => {setDataTotal(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la data.", error.response);});
    }
/* Fin */

    const tabla = useReactTable(
        {
            data: datosReporte,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-3">
                    <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
                    <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
                    onChange={e=>setFiltering(e.target.value)}/>
                </div>
                <div className="col-sm-9 col-md-9 col-lg-9">
                    {/*<button className="transparent-button"><span className="textosNormal textos"><span className="fa fa-file-excel-o"></span>&nbsp;Exportar Excel</span></button>*/}
                    <ExportarExcel datosReporte={datosReporte}/>
                </div>
            </div>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id} className="textos">
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                {tabla.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                    ))}
                  </tr>
                ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={11} align="right"><span className="textos">Total Final:</span></td>
                        <td>
                            {dataTotal.length > 0 ?
                                dataTotal.map((val) => {
                                return (
                                    <span key={1} className="textos">$ {val.totalFinal}</span>
                                );
                            }) : ''}
                        </td>
                    </tr>
                </tfoot>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ReporteSemanal