import React, { useState } from "react";
import Axios from "axios";
import config from '../../config';
import Swal from "sweetalert2";
import SweetAlert from "../SweetAlert";
import { validaCambioContrasena} from "../validaciones/Validaciones";

function CambiarContrasena() {
    const [contrasenaUsuario, setContrasenaUsuario] = useState('');
    const [confirmarContrasena, setConfirmarContrasena] = useState('');
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    const handleActualizar = async () => {
        console.log('Token enviado:', token);
        if (validaCambioContrasena(contrasenaUsuario,confirmarContrasena)){ // Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaCambioContrasena(contrasenaUsuario,confirmarContrasena)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            try {
                const response = await Axios.post(
                    `${config.apiUrl}/api/cambiarContrasena`,{contrasenaUsuario,confirmarContrasena,},
                    {headers: {Authorization: `Bearer ${token}`,},}
                );
                titulo = "<strong>Atención</strong>";
                icono = "success";
                mensaje = "Contraseña cambiada con éxito!"
                SweetAlert(mensaje, "error","<strong>Atención</strong>")
            } catch (error) {
                console.error('Error al actualizar la contraseña', error);
                titulo = "<strong>Atención</strong>";
                icono = "error";
                mensaje = "<i>Ocurrió un error al actualizar la contraseña</i>"
                SweetAlert(mensaje, "error","<strong>Atención</strong>")
            }
        }
    };

    return (
        <div className='card'>
            <div className='card-body'>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-3 col-md-3 col-lg-3'></div>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-lock"></span>&nbsp;Nueva Contraseña:</span></div>
                            <div className="col-sm-4 col-md-4 col-lg-4">
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    placeholder="Contraseña"
                                    className="textosCajas textosNormal"
                                    onChange={(event) => { setContrasenaUsuario(event.target.value); }}
                                    required
                                    maxLength={16}
                                />
                            </div>
                            <div className='col-sm-3 col-md-3 col-lg-3'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-3 col-md-3 col-lg-3'></div>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-unlock-alt"></span>&nbsp;Confirmar Contraseña:</span></div>
                            <div className='col-sm-4 col-md-4 col-lg-4'>
                                <input
                                    id="confirmPassword"
                                    name="confirmPassword"
                                    type="password"
                                    placeholder="Confirmar"
                                    className="textosCajas textosNormal"
                                    onChange={(event) => { setConfirmarContrasena(event.target.value); }}
                                    required
                                    maxLength={16}
                                />
                            </div>
                            <div className='col-sm-3 col-md-3 col-lg-3'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5'></div>
                            <div className="col-sm-2 col-md-2 col-lg-2 text-center">
                                <button
                                    type="button"
                                    className="botonAccion"
                                    onClick={handleActualizar}
                                >
                                    <h5><span className="fa fa-refresh"></span></h5>&nbsp;Actualizar
                                </button>
                            </div>
                            <div className='col-sm-5 col-md-5 col-lg-5'></div>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-4 col-md-4 col-lg-4'></div>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <li className="textosNormal">La contraseña debe tener un mínimo de 10 caracteres.</li>
                            <li className="textosNormal">La contraseña debe contener al menos una letra mayúscula.</li>
                            <li className="textosNormal">La contraseña debe contener al menos una letra minúscula.</li>
                            <li className="textosNormal">La contraseña debe contener al menos un número.</li>
                            <li className="textosNormal">La contraseña debe contener al menos un símbolo.</li>
                        </div>
                        <div className='col-sm-4 col-md-4 col-lg-4'></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CambiarContrasena;