import React from 'react';
import '../styleesheets/Dashboard.css';
import ListadoVehiculos from './vehiculos/ListadoVehiculos';
import { Modal, Button } from 'react-bootstrap';  
import { useState, useEffect } from "react";
import RegistrarVehiculo from './vehiculos/RegistrarVehiculo';

function Vehiculos() {
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const [showList, setShowList] = useState(false);
    const cerrarModal = ()=>{
        setShowModal(false);
        setShowList(!showList);
    }
    return (
        <main className='overflow-auto'>
            <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div className='card'>
                        <div className='card-header'>
                            <div className='row'>
                                <div className='col-sm-10 col-md-10 col-lg-10'>
                                <h3><span className='fa fa-car'></span>&nbsp;Gestión de Vehículos</h3>
                                </div>
                                <div className='col-sm-2 col-md-2 col-lg-2'>
                                    <button className='btn-agregar' onClick={handleShow}><span className='fa fa-plus-square'></span>&nbsp;Nuevo</button>
                                </div>
                            </div>
                        </div>
                        <div className='card-body'>
                            <ListadoVehiculos showList={showList}/>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showModal} onHide={handleClose} style={{ maxWidth: '100%' }}>
                <Modal.Body>
                    <RegistrarVehiculo/>
                </Modal.Body>
                <Modal.Footer>
                   <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal> 
        </main>
    );
}

export default Vehiculos;