import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import { validarProveedor} from "../validaciones/Validaciones";

function RegistrarProveedor(){
    const [razonSocial, setRazonSocial] = useState("");
    const [direccion, setDireccion] = useState("");
    const [telefono, setTelefono] = useState("");
    const [email, setEmail] = useState("");
    const [rut, setRut] = useState("");
    const [banco, setBanco] = useState("");
    const [numeroCuenta, setNumeroCuenta] = useState("");
    const [tipoCuenta, setTipoCuenta] = useState("");
    const [bancosList, setBancos] = useState([]);
    const [tipoCuentasList, setTipoCuentas] = useState([]);
    const token = localStorage.getItem('token');
    const [show, setShow] = useState(false);
    let titulo = ""
    let mensaje = ""
    let icono = ""


    useEffect(() => {
        listarBancos();
        listarTipoCuentas();
    }, []);

    const limpiarCampos = () => {
        setRut("");
        setRazonSocial("");
        setDireccion("");
        setTelefono("");
        setEmail("");
        setRut("");
      };
  /* Obtenemos los datos Bancarios */
    const listarBancos = async() =>{
        await Axios.get(`${config.apiUrl}/api/bancos`,{headers: {'Authorization': token,},})
        .then((response) => {setBancos(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de bancos:", error.response);});
    };
    const listarTipoCuentas = async() =>{
    await Axios.get(`${config.apiUrl}/api/tipoCuentas`,{headers: {'Authorization': token,},})
    .then((response) => {setTipoCuentas(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de Tipo de Cuentas Bancarias:", error.response);});
    };
  /* Fin */

    const registrarProveedor = async() => { // Registramos los datos del proveedor, previamente validaddos
        if (validarProveedor(rut, razonSocial, direccion, telefono, email, numeroCuenta, banco, tipoCuenta)){ // Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validarProveedor(rut, razonSocial, direccion, telefono, email, numeroCuenta, banco, tipoCuenta)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosProveedor = {
                rut: rut.toUpperCase(), razonSocial: razonSocial.toUpperCase(), direccion: direccion.toUpperCase(), telefono: telefono,
                email: email.toUpperCase(),numeroCuenta: numeroCuenta, idBanco: banco, idTipoCuenta: tipoCuenta,
            }
            await Axios.post(`${config.apiUrl}/api/proveedores`,datosProveedor,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                limpiarCampos();
                setShow(false);
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el proveedor</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
        <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-address-book'></span>&nbsp;Registrar Proveedor</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="razonSocial" name="razonSocial" type="text" placeholder="Razón Social" class="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setRazonSocial(event.target.value);}} required maxLength={100}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut" class="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setRut(event.target.value);}} maxLength={11}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa fa-building"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="direccion" name="direccion" type="text" placeholder="Dirección" class="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setDireccion(event.target.value);}} maxLength={100}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-envelope"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="email" name="email" type="text" placeholder="Email" class="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setEmail(event.target.value);}} maxLength={100}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-phone-square"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="telefono" name="telefono" type="text" placeholder="912345678" class="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setTelefono(event.target.value);}} maxLength={9}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-university"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal" onChange={(event) => {setBanco(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1">
                                <option value="">--SELECCIONE--</option>
                                {bancosList.length > 0 ?
                                    bancosList.map((val) => {
                                    return (
                                        <option value={val.idBanco} key={val.idBanco}>{val.nombreBanco}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-credit-card-alt"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="telefono" name="telefono" type="text" placeholder="Nº Cuenta" class="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNumeroCuenta(event.target.value);}} maxLength={12}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 00001234566</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-briefcase"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal" onChange={(event) => {setTipoCuenta(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="tipoCuenta">
                                <option value="">--SELECCIONE--</option>
                                {tipoCuentasList.length > 0 ?
                                    tipoCuentasList.map((val) => {
                                    return (
                                        <option value={val.idTipoCuenta} key={val.idTipoCuenta}>{val.tipoCuenta}</option>
                                    );
                                }) : ''}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={registrarProveedor}><span className="fa fa-floppy-o"></span>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarProveedor