import React from "react";
import config from '../../config'
import 
    { 
        useReactTable, getCoreRowModel, 
        getPaginationRowModel, flexRender,
        getSortedRowModel, getFilteredRowModel
    } from "@tanstack/react-table";
import { useState, useEffect } from "react";    
import Axios from "axios";
import EditarGasto from "./EditarGasto";
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';


function ListadoGastos({showList}){
    const [idProveedorInsumos,setIdProveedorInsumos] = useState(0);
    const [gastosList, setGastos] = useState([]);
    const [datoGasto, setDatosGastos] = useState([]);
    const [filtering, setFiltering] = useState("");
    const [sorting, setSorting] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const token = localStorage.getItem('token');
    const columnas = [
        {header: "Nº Documento",accessorKey: 'nroDocumento'},
        {header: "Proveedor",accessorKey: 'nombreProveedor'},
        {header: "Insumo",accessorKey: 'nombreInsumo'},
        {header: "Cant. Insumos",accessorKey: 'cantidadInsumos'},
        {header: "$ Compra",accessorKey: 'precioCompra'},
        {header: "$ Venta",accessorKey: 'precioVenta'},
        {header: "Fecha",accessorKey: 'fechaCompraFormato'},
        {header: "Estado",
            accessorKey: 'estado',
            cell:(fila)=>{
                return(
                    (fila.getValue('estado') ? 
                        <span className="textosNormal"><span className="fa fa-check-circle-o text-success"></span></span> : 
                        <span className="textosNormal"><span className="fa fa-times-circle text-danger"></span></span>
                    )
                )
            }
        },
        {header: "Editar/Eliminar",
            accessorKey: 'idProveedorInsumos',
            cell: (fila) => {
                return (
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <button className="transparent-button" onClick={() => editarGastos(fila.getValue('idProveedorInsumos'))} title="Editar">
                                        <span className="textos textosEditar"><span className="fa fa-pencil-square-o"></span></span>
                                    </button>
                                </td>
                                <td>
                                    <button className="transparent-button" onClick={()=>eliminarRegistro(fila.getValue('idProveedorInsumos'))} title="Dar de baja">
                                        <span className="textos text-danger"><span className="fa fa-trash"></span></span>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                );
            },
        },
    ]
    useEffect(() => {
        listarGatos();
    }, [showList]);
    const editarGastos = (idProveedorInsumos)=>{
        setIdProveedorInsumos(idProveedorInsumos);
        setShowModal(true);
    }
    const cerrarModal = ()=>{
        listarGatos();
        setShowModal(false);
    }
/*  Doy de baja un registro */
const eliminarRegistro = async(id) =>{
    Swal.fire({
        title: 'Atención!', text: "¿Está seguro de continuar?",
        icon: 'warning', showCancelButton: true,confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33', confirmButtonText: 'Confirmar'
    }).then((result) => {
        if (result.isConfirmed) {
            eliminarGasto(id);
        }
    });
}
const eliminarGasto = async (id) => {
    const datosRegistro = {
        estado: 0
    }
    await Axios.put(`${config.apiUrl}/api/proveedores/insumos/delete/` + id,datosRegistro, { headers: { 'Content-Type': 'application/json', 'Authorization': token } })
        .then((response) => { listarGatos() })
        .catch((error) => { console.error("Atención: ha ocurrido un error.", error.response); });
}
/* Fin */
    const listarGatos = async() =>{
        await Axios.get(`${config.apiUrl}/api/proveedores/insumos`,{headers: {'Authorization': token,},})
        .then((response) => {setGastos(response.data);})
        .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
    };
    const tabla = useReactTable(
        {
            data: gastosList,columns: columnas, getCoreRowModel: getCoreRowModel(), 
            getPaginationRowModel: getPaginationRowModel(), getSortedRowModel: getSortedRowModel(),
            getFilteredRowModel: getFilteredRowModel(),
            state: {
                sorting,
                globalFilter: filtering
            },
            onSortingChange: setSorting,
            onGlobalFilterChange: setFiltering,
        });
    return(
        <div>
            <span className="textos"><span className="fa fa-search"></span>&nbsp;Consultar:</span>&nbsp;
            <input type="text" value={filtering} className="textosCajas textosNormal text-uppercase"
            onChange={e=>setFiltering(e.target.value)}/>
            <table className="table table-hover table-responsive-lg"> 
                <thead>
                    {
                       tabla.getHeaderGroups().map(headerGroup=> (
                            <tr key={headerGroup.id} className="textos">
                                {
                                    headerGroup.headers.map(header=>(
                                        <th key={header.id} 
                                            onClick={header.column.getToggleSortingHandler()}>
                                            {
                                                flexRender(header.column.columnDef.header, header.getContext())}
                                                {
                                                {
                                                    'asc' : "⬆", 'desc' : "⬇"
                                                }[header.column.getIsSorted() ?? null]
                                                }
                                        </th>
                                    ))
                                }
                            </tr>
                        )) 
                    }
                </thead>
                <tbody>
                    {tabla.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="textosNormal">{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                        ))}
                    </tr>
                    ))}
                </tbody>
            </table>
            <table align="center">
                <tbody>
                    <tr>
                        <td><button onClick={()=> tabla.setPageIndex(0)} className="btnPaginadorA"><span className="fa fa-step-backward"></span></button></td>
                        <td><button onClick={()=> tabla.previousPage()} className="btnPaginadorCentral"><span className="fa fa-backward"></span></button></td>
                        <td><button onClick={()=> tabla.nextPage()} className="btnPaginadorCentral"><span className="fa fa-forward"></span></button></td>
                        <td><button onClick={()=> tabla.setPageIndex(tabla.getPageCount()-1)} className="btnPaginadorB"><span className="fa fa-step-forward"></span></button></td>
                    </tr>
                </tbody>
            </table>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Body>
                    <EditarGasto id={idProveedorInsumos}/>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={()=>cerrarModal()}><span className='textosNormal'><span className='fa fa-close'></span>&nbsp;Cerrar</span></button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ListadoGastos