import React from "react";
import config from '../../config'
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import { validarGasto} from "../validaciones/Validaciones";

function RegistrarGasto(){
    const [nroDocumento, setNroDocumento] = useState("");
    const [idProveedor, setIdProveedor] = useState(0);
    const [idInsumo, setIdInsumo] = useState(0);
    const [cantidadInsumos, setCantidadInsumos] = useState(0);
    const [precioCompra, setPrecioCompra] = useState(0);
    const [precioVenta, setPrecioVenta] = useState(0);
    const [fechaRecepcion, setFechaRecepcion] = useState("1900-01-01");
    const [proveedoresList, setProveedores] = useState([]);
    const [tipoInsumosList, setTipoInsumos] = useState([]);
    const [insumosList, setInsumos] = useState([]);
    const [show, setShow] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""
    useEffect(() => {
        listarProveedores();
        listarTipoInsumos();
    }, []);
/*  Obtengo una lista de proveedores */
    const listarProveedores = async() =>{
        await Axios.get(`${config.apiUrl}/api/proveedores`,{headers: {'Authorization': token,},})
        .then((response) => {setProveedores(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener proveedores:", error.response);});
    };
/*  Obtengo una lista de los tipos de Insumos */
    const listarTipoInsumos = async() =>{
        await Axios.get(`${config.apiUrl}/api/tipoInsumos`,{headers: {'Authorization': token,},})
        .then((response) => {setTipoInsumos(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de tipos de Insumos:", error.response);});
    };
/* Listo los insumos en base a un tipo */
const listarInsumos = async(idTipoInsumo) =>{
    await Axios.get(`${config.apiUrl}/api/insumos/tipo/` + idTipoInsumo,{headers: {'Authorization': token,},})
    .then((response) => {setInsumos(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de Insumos:", error.response);});
};
/* Registro el Gasto */
/* Registro los datos del Insumo */
const registrarGasto = async() => {
    if (validarGasto(nroDocumento, idProveedor, idInsumo, cantidadInsumos, precioCompra, precioVenta, fechaRecepcion)){
        titulo = "<strong>Atención</strong>";
        icono = "error";
        mensaje = validarGasto(nroDocumento, idProveedor, idInsumo, cantidadInsumos, precioCompra, precioVenta, fechaRecepcion);
        SweetAlert(mensaje, "error","<strong>Atención</strong>");
    }else{
        const datosGastos = {
            nroDocumento: nroDocumento,idProveedor: idProveedor,idInsumo: idInsumo,cantidadInsumos: cantidadInsumos,
            precioCompra: precioCompra,precioVenta: precioVenta,fechaCompra: fechaRecepcion
        }
        await Axios.post(`${config.apiUrl}/api/proveedores/insumos`,datosGastos,{headers:{'Content-Type':'application/json','Authorization': token}})
        .then((response) => {
            setShow(false);
            titulo = "<strong>Atención</strong>";
            mensaje = response.data.message;
            icono = response.data.registroCreado ? "success" : "error";
            SweetAlert(mensaje, icono,titulo);
        })
        .catch((error) => {
            titulo = "<strong>Atención</strong>";
            mensaje =  "<i>Atención: Hubo un problema al crear el registro</i>";
            icono = "error";
            SweetAlert(mensaje, icono,titulo);
        });
    }
};
/*  Fin */
    return(
        <div className='card'>
            <div className='card-header'>
                <h3><span className='fa fa-money'></span>&nbsp;Registrar Gastos</h3>
            </div>
            <div className='card-body'>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-barcode"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <input id="nroDocumento" name="nroDocumento" type="text" placeholder="Nro. Documento" class="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setNroDocumento(event.target.value);}} required maxLength={10} tabIndex={1}/>
                            </div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-vcard"></span></span></div>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setIdProveedor(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={2}>
                                    <option value={0}>--SELECCIONE--</option>
                                    {proveedoresList.length > 0 ?
                                            proveedoresList.map((val) => {
                                            return (
                                                <option value={val.idProveedor} key={val.idProveedor}>{val.razonSocial}</option>
                                            );
                                        }) : ''}
                                </select>
                            </div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-flask"></span></span></div>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal" onChange={(event) => {listarInsumos(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={3}>
                                <option value="">--SELECCIONE--</option>
                                {tipoInsumosList.length > 0 ?
                                    tipoInsumosList.map((val) => {
                                    return (
                                        <option value={val.id} key={val.id}>{val.descripcion}</option>
                                    );
                                }) : ''}
                            </select>
                            </div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-flask"></span></span></div>
                            <div className="col-sm-10 col-md-10 col-lg-10">
                                <select className="textosSelect textosNormal" onChange={(event) => {setIdInsumo(event.target.value);}}
                                    aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={4} id="idInsumo">
                                    <option value="">--SELECCIONE--</option>
                                    {insumosList.length > 0 ?
                                        insumosList.map((val) => {
                                        return (
                                            <option value={val.idInsumo} key={val.idInsumo}>{val.nombreInsumo}</option>
                                        );
                                    }) : ''}
                                </select>
                            </div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-plus-square"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <input id="cantidadInsumos" name="cantidadInsumos" type="number" placeholder="Cantidad" class="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setCantidadInsumos(event.target.value);}} required tabIndex={5}/>
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-5"></div>   
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-usd"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <input id="precioCompra" name="precioCompra" type="number" placeholder="Precio Compra" class="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setPrecioCompra(event.target.value);}} required tabIndex={6}/>
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">(Al detalle)</span>
                            </div>   
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-usd"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <input id="precioVenta" name="precioVenta" type="number" placeholder="Precio Venta" class="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setPrecioVenta(event.target.value);}} required tabIndex={6}/>
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">(Al detalle)</span>
                            </div>   
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-calendar"></span></span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <input id="fechaRecepcion" name="fechaRecepcion" type="date" placeholder="Precio Venta" class="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setFechaRecepcion(event.target.value);}} required tabIndex={7}/>
                            </div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">Fecha Recepción</span>
                            </div>    
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={()=>registrarGasto()} tabIndex={8}><h5><span className="fa fa-save"></span></h5>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default RegistrarGasto