import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import { validaMarca} from "../validaciones/Validaciones";

function RegistrarMarca({showList}){
    const [nombreMarca, setNombreMarca] = useState("");
    const [show, setShow] = useState(false);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""
    useEffect(() => {

    }, [showList]);
/* Registramos nuestro Tipo de Insumo */
    const registrarTipoInsumo = async() => {
        if (validaMarca(nombreMarca)){
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaMarca(nombreMarca)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosMarca = {
                nombre: nombreMarca.toUpperCase(),estado: 1
            }
            await Axios.post(`${config.apiUrl}/api/marcas`,datosMarca,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                setShow(false);
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar la marca.</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-trademark'></span>&nbsp;Registrar Nueva Marca</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-info-circle"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="nombreMarca" name="nombreMarca" type="text" placeholder="Descripción" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setNombreMarca(event.target.value);}} required tabIndex={1}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={registrarTipoInsumo} tabIndex={2}><span className="fa fa-floppy-o"></span>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarMarca