import React from 'react';
import '../styleesheets/Dashboard.css'

function RegistrarUsuario() {
    return (
        <main className='overflow-auto'>
            <div className='dashboard-titulo'>
                <h1>
                    Registrar Usuario
                </h1>
            </div>
        </main>
    );
    
}

export default RegistrarUsuario;
