import React from 'react';
import { useState, useEffect } from "react";   
import Axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import config from '../config'
import '../styleesheets/Dashboard.css';
import avatar from '../imagenes/logo-container_v2.png'; 
import { jwtDecode } from 'jwt-decode';
const images = require.context('../icons/', true)


localStorage.removeItem('idTipoPerfil');
localStorage.removeItem('idPerfil');
const dashboardItemsAdmin = [
  { iconPath: 'icons-cliente.png', altText: 'Dashhboard', buttonText: 'Home', path: '/', classIcon: 'fa fa-home', classTextos: 'enlaces' },
  { iconPath: 'icons-cliente.png', altText: 'Clientes', buttonText: 'Clientes', path: '/clientes', classIcon: 'fa fa-address-book', classTextos: 'enlaces' },
  { iconPath: 'icons-tareas.png', altText: 'Trabajos', buttonText: 'Trabajos', path: '/trabajos', classIcon: 'fa fa-wrench', classTextos: 'enlaces' },
  { iconPath: 'icons-tareas.png', altText: 'Notificaciones', buttonText: 'Notificaciones', path: '/notificaciones', classIcon: 'fa fa-envelope', classTextos: 'enlaces' },
  { iconPath: 'icons-proveedores.png', altText: 'Proveedores', buttonText: 'Proveedores', path: '/proveedores', classIcon: 'fa fa-vcard', classTextos: 'enlaces' },
  { iconPath: 'icons-usuarios.png', altText: 'Mantenedor Usuarios', buttonText: 'Usuarios', path: '/usuarios', classIcon: 'fa fa-group', classTextos: 'enlaces' },
  { iconPath: 'icons-inventario.png', altText: 'Vehiculos', buttonText: 'Vehiculos', path: '/vehiculos', classIcon: 'fa fa-car', classTextos: 'enlaces' },
  { iconPath: 'icons-inventario.png', altText: 'Inventario', buttonText: 'Inventario', path: '/inventario', classIcon: 'fa fa-cubes', classTextos: 'enlaces' },
  { iconPath: 'icons-gastos.png', altText: 'Gastos', buttonText: 'Gastos', path: '/gastos', classIcon: 'fa fa-money', classTextos: 'enlaces' },
  { iconPath: 'icons-gastos.png', altText: 'Reportería', buttonText: 'Reportes', path: '/reportes', classIcon: 'fa fa-print', classTextos: 'enlaces' },
  { iconPath: 'icons-gastos.png', altText: 'Varios', buttonText: 'Parametrías Varias', path: '/varios', classIcon: 'fa fa-cogs', classTextos: 'enlaces' },
  { iconPath: 'icons-gastos.png', altText: 'Contraseña', buttonText: 'Cambiar Contraseña', path: '/editarUsuario', classIcon: 'fa fa-unlock-alt', classTextos: 'enlaces' }
];
const dashboardItemsMecanico = [
  { iconPath: 'icons-cliente.png', altText: 'Dashhboard', buttonText: 'Home', path: '/', classIcon: 'fa fa-home', classTextos: 'enlaces' },
  { iconPath: 'icons-cliente.png', altText: 'Clientes', buttonText: 'Clientes', path: '/clientes', classIcon: 'fa fa-address-book', classTextos: 'enlaces' },
  { iconPath: 'icons-tareas.png', altText: 'Trabajos', buttonText: 'Trabajos', path: '/trabajos', classIcon: 'fa fa-wrench', classTextos: 'enlaces' },
  { iconPath: 'icons-tareas.png', altText: 'Notificaciones', buttonText: 'Notificaciones', path: '/notificaciones', classIcon: 'fa fa-envelope', classTextos: 'enlaces' },
  { iconPath: 'icons-inventario.png', altText: 'Vehiculos', buttonText: 'Vehiculos', path: '/vehiculos', classIcon: 'fa fa-car', classTextos: 'enlaces' },
  { iconPath: 'icons-inventario.png', altText: 'Inventario', buttonText: 'Inventario', path: '/inventario', classIcon: 'fa fa-cubes', classTextos: 'enlaces' },
  { iconPath: 'icons-gastos.png', altText: 'Varios', buttonText: 'Parametrías Varias', path: '/varios', classIcon: 'fa fa-cogs', classTextos: 'enlaces' },
  { iconPath: 'icons-gastos.png', altText: 'Contraseña', buttonText: 'Cambiar Contraseña', path: '/editarUsuario', classIcon: 'fa fa-unlock-alt', classTextos: 'enlaces'}
];
function Sidebar({ onLogout }) {
    const navigate = useNavigate();
    const [idTipoPerfil,setIdTipoPerfil] = useState(0);
    const token = localStorage.getItem('token');
  useEffect(()=>{
    buscarUsuario();
  },[])

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };
/*  Busco los datos del usuario logueado, para así obtener su tipo de perfil y mostrar las opciones del menú,
    como mejora futura, se pretende aplicar un menú dinámico, obteniendo las opciones desde la BDD. */
  const buscarUsuario = async() =>{
    await Axios.get(`${config.apiUrl}/api/usuarios/login/` + jwtDecode(token).idUsuario,{headers: {'Authorization': token,},})
    .then((response) => {
        setIdTipoPerfil(response.data.idTipoPerfil);
        localStorage.setItem('idTipoPerfil', response.data.idTipoPerfil);
        localStorage.setItem('idPerfil', response.data.idPerfil);
      })
    .catch((error) => {console.error("Atención: ha ocurrido un error.", error.response);});
  };

return (
  <nav className='sidebar'>
    <div className='bgAvatar'>
      <div>
        <img src={avatar} alt='Header Graphic'/>
      </div>
    </div>
    <div>
      <ul>
          {idTipoPerfil === 1 ? 
            dashboardItemsAdmin.map((item, index) => (
              <li key={index}>
                <Link to={item.path} className={item.classTextos}><span className={item.classIcon}>&nbsp;</span>{item.buttonText}</Link>
              </li>
        )) : 
            dashboardItemsMecanico.map((item, index) => (
              <li key={index}>
              <Link to={item.path} className={item.classTextos}><span className={item.classIcon}>&nbsp;</span>{item.buttonText}</Link>
              </li>
          ))
        }
      </ul>
      <ul>
        <li>
          <button onClick={handleLogout} className='enlaces'>Cerrar Sesión&nbsp;  <span className='fa fa-sign-out'></span></button>
        </li>
      </ul>
    </div>
    <div className='copyRight'>
      <span className='textosBlanco'>Derechos Reservados ©2023, Leonhardt Mechanics Services</span>
    </div>
  </nav>
);
}

export default Sidebar;