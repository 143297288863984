import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import Swal from "sweetalert2";
import SweetAlert from "../SweetAlert";
import {validaUsurio} from "../validaciones/Validaciones";

function RegistrarUsuario(){
    const [nombreUsuario, setNombres] = useState("");
    const [apellidosUsuario, setApellidos] = useState("");
    const [rutUsuario, setRut] = useState("");
    const [emailUsuario, setEmail] = useState("");
    const [telefonoUsuario, setTelefono] = useState("");
    const [idTipoPerfil, setTipoPerfil] = useState("");
    const [tipoPerfilesList, setTipoPerfiles] = useState([]);
    const [contrasenaUsuario, setContrasena] = useState("");
    const [confirmarContrasena, setConfirmar] = useState("");
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        listarTipoPerfiles();
    }, []);

    const limpiarCampos = () => {
        setRut("");
        setNombres("");
        setApellidos("");
        setTelefono("");
        setEmail("");
        setRut("");
      };
/*  Listo los tipos de Perfiles */
    const listarTipoPerfiles = async() =>{
        await Axios.get(`${config.apiUrl}/api/tipoPerfil`,{headers: {'Authorization': token,},})
        .then((response) => {setTipoPerfiles(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de Tipo de Cuentas Bancarias:", error.response);});
    };
/*  Fin */
    const registrarUsuario = async() => {
        if (validaUsurio(rutUsuario, nombreUsuario, apellidosUsuario, telefonoUsuario, emailUsuario, idTipoPerfil, contrasenaUsuario, confirmarContrasena)){// Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaUsurio(rutUsuario, nombreUsuario, apellidosUsuario, telefonoUsuario, emailUsuario, idTipoPerfil, contrasenaUsuario, confirmarContrasena)
            SweetAlert(mensaje, "error","<strong>Atención</strong>") 
        }else{ 
            const datosUsuario = {
                rut: rutUsuario, nombres: nombreUsuario.toUpperCase(), apellidos: apellidosUsuario.toUpperCase(),
                telefono: telefonoUsuario, email: emailUsuario.toUpperCase(), idTipoPerfil: idTipoPerfil,
                contrasena: contrasenaUsuario
            }
            await Axios.post(`${config.apiUrl}/api/usuarios`,datosUsuario,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                limpiarCampos();
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo)
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el usuario</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    };
    return(
    <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-address-book'></span>&nbsp;Nuevo Usuario</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="fname" name="name" type="text" placeholder="Nombres" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNombres(event.target.value);}} required maxLength={50}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'>
                            <input id="fname" name="name" type="text" placeholder="Apellidos" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setApellidos(event.target.value);}} required maxLength={50}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setRut(event.target.value);}} maxLength={11}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-envelope"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="email" name="email" type="text" placeholder="email@dominio.cl" className="textosCajas2 textosNormal text-uppercase"
                            onChange={(event) => {setEmail(event.target.value);}} maxLength={100}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-phone-square"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="fname" name="name" type="text" placeholder="912345678" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setTelefono(event.target.value);}} maxLength={9}/>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-user-plus"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setTipoPerfil(event.target.value);}}
                                    aria-label="Dropdown" aria-describedby="select-addon1">
                                    <option value="">--SELECCIONE--</option>
                                    {tipoPerfilesList.length > 0 ?
                                        tipoPerfilesList.map((val) => {
                                        return (
                                            <option value={val.idPerfil} key={val.idPerfil}>{val.nombrePerfil}</option>
                                        );
                                    }) : ''}
                                </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-lock"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="contrasena" name="contrasena" type="password" placeholder="Contraseña" className="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setContrasena(event.target.value);}} maxLength={16}/> 
                            </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-unlock-alt"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <input id="confirmarContrasena" name="confirmarContrasena" type="password" placeholder="Confirmar Contraseña" className="textosCajas textosNormal text-uppercase"
                                onChange={(event) => {setConfirmar(event.target.value);}} maxLength={16}/>
                            </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-center">
                            <button type="submit" className="btn-agregar" onClick={registrarUsuario}><span className="fa fa-floppy-o"></span>&nbsp;Guardar</button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left">
                            <li className="textosNormal">La contraseña debe tener un mínimo de 10 caracteres.</li>
                            <li className="textosNormal">La contraseña debe contener al menos una letra mayúscula.</li>
                            <li className="textosNormal">La contraseña debe contener al menos una letra minúscula.</li>
                            <li className="textosNormal">La contraseña debe contener al menos un número.</li>
                            <li className="textosNormal">La contraseña debe contener al menos un símbolo.</li>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarUsuario