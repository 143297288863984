import React from "react";
import config from '../../config';
import { useState, useEffect } from "react";    
import Axios from "axios";
import '../../styleesheets/imprimir.css';
import avatar from '../../imagenes/logo-container_v2.png'; 
import SweetAlert from "../SweetAlert";
import DetalleInsumoTrabajo from "./DetalleInsumoTrabajo";

function DetalleTrabajo({id}){
    const token = localStorage.getItem('token');
    const [detalleTrabajo, setDetalleTrabajo] = useState("");
    const [fechaTrabajo, setFechaTrabajo] = useState("");
    const [proximaMantencion, setProximaMantención] = useState("");
    const [requiereNotificacion, setRequiereNotificacion] = useState(false);
    const [costoManoObra, setCostoManoObra] = useState(0);
    const [costoInsumos, setCostoInsumos] = useState(0);
    const [costoTotal, setCostoTotal] = useState(0);
    const [detalleVehiculo, setDetalleVehiculo] = useState("");
    const [patenteVehiculo, setPatenteVehiculo] = useState("");
    const [nombreCliente, setNombreCliente] = useState("");
    const [telefonoCliente, setTelefonoCliente] = useState("");
    const [emailCliente, setEmailCliente] = useState("");
    const [idEstadoTrabajo, setIdEstadoTrabajo] = useState(0);
    const [observacionTrabajo, setObservacion] = useState("");
    const [nombreMecanico, setNombreMecanico] = useState("");
    const [estadoTrabajo, setEstadoTrabajo] = useState("");
    const idTipoPerfil = localStorage.getItem('idTipoPerfil');
    const [insumosTrabajoList, setInsumosTrabajo] = useState([]);
    let titulo = "";
    let mensaje = "";
    let icono = "";
    useEffect(() => {
        getDatosTrabajo()
        listarInsumosTrabajo()
    },[]);
/*  Imprimimos el contenido del detalle del trabajo */
    const printDiv = () => {
        window.print();
    };
/*  Listo los insumos asociados al trabajo */
const listarInsumosTrabajo = async() =>{
    await Axios.get(`${config.apiUrl}/api/trabajos/detalleTrabajos/` + id,{headers: {'Authorization': token,},})
    .then((response) => {setInsumosTrabajo(response.data);})
    .catch((error) => {console.error("Hubo un error al obtener la lista de usuarios:", error.response);});
};
/*  Fin */
    const enviarNotificacion = async() => { // Armamos y enviamos la notificación al cliente.
        let textoMensaje = ""
            textoMensaje = textoMensaje + '<p style="font-family: tahoma;font-size: 12px;">Estimado/a ' + nombreCliente + ',</p>';
            textoMensaje = textoMensaje + '<p style="font-family: tahoma;font-size: 12px;">Mediante el presente correo, informamos a usted que el trabajo realizado a su vehiculo ' + detalleVehiculo + ', patente ' + patenteVehiculo + ', se encuentra finalizado.</p>';
            textoMensaje = textoMensaje + '<p style="font-family: tahoma;font-size: 12px;">A continuación encontrará el detalle del trabajo realizado:</p>';
            textoMensaje = textoMensaje + '<table width="750" border="0" cellpadding="2" cellspacing="1" bgcolor="#e5e5e5">';
            textoMensaje = textoMensaje + '<thead>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td style="font-family: tahoma;font-size: 12px;" colspan="2">' + detalleTrabajo + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '	</thead>';
            textoMensaje = textoMensaje + '	<tbody>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Fecha:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + fechaTrabajo + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Prox. Mantención:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + proximaMantencion + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Req. Notificación:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">'+  (requiereNotificacion ? "SI" : "NO") + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Costo Mano Obra:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">$ '+ costoManoObra +'</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Costo Insumos:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">$ ' + costoInsumos + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Costo Total:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">$ ' + costoTotal + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Vehículo:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + detalleVehiculo + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">'
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Cliente:</td>'
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + nombreCliente + '</td>'
            textoMensaje = textoMensaje + '		</tr>'
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Teléfono:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + telefonoCliente + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Patente:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + patenteVehiculo + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;">Observaciones:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + observacionTrabajo + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;"> Realizado Por:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + nombreMecanico + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '		<tr bgcolor="#FFFFF">';
            textoMensaje = textoMensaje + '			<td width="200" style="font-family: tahoma;font-size: 12px;"> Estado:</td>';
            textoMensaje = textoMensaje + '			<td width="550" style="font-family: tahoma;font-size: 12px;">' + estadoTrabajo + '</td>';
            textoMensaje = textoMensaje + '		</tr>';
            textoMensaje = textoMensaje + '	</tbody>';
            textoMensaje = textoMensaje + '</table>';
            textoMensaje = textoMensaje + '<br/>';
            if (insumosTrabajoList.length > 0) {
                textoMensaje = textoMensaje + 'Insumos utilizados en el trabajo: <br/>';
                textoMensaje = textoMensaje + '<table width="750" border="0" cellpadding="2" cellspacing="1" bgcolor="#e5e5e5">'
                textoMensaje = textoMensaje + '<thead>'
                textoMensaje = textoMensaje + '<tr>Tipo</td><td>Insumo</td><td>Cantidad</td><td>Costo $</td>'
                textoMensaje = textoMensaje + '</thead>'
                textoMensaje = textoMensaje + '<tbody>'
                textoMensaje = textoMensaje + '<tr>'
                {insumosTrabajoList.map((val) => {
                    return (
                            textoMensaje = textoMensaje + '<tr bgcolor="#FFFFF"><td>'+ val.tipoInsumo +'</td><td>' + val.nombreInsumo + '</td><td>'+ val.cantidadInsumos + '</td><td>' + val.costoInsumos  + '</td></tr>'
                        );
                })
                }
                textoMensaje = textoMensaje + '</tbody>'
                textoMensaje = textoMensaje + '</table>'
            }
            textoMensaje = textoMensaje + '<p style="font-family: tahoma;font-size: 12px;">Saludos Cordiales</p>';
            textoMensaje = textoMensaje + '<p style="font-family: tahoma;font-size: 12px;">Taller Mecánico Leonhardt ©2023.</p><img src="http://intranet.appleonhardtaller.cl/static/media/logo-container_v2.9217330ffe6f9053c730.png" alt="Taller Leonhardt" width="200">';
            textoMensaje = textoMensaje + '<p style="font-family: tahoma;font-size: 12px;"><small>Si no desea recibir más notificaciones, favor responda a este correo con "Borrar".</small></p>';
        const datosNotificacion = {
            emailDestinatario: emailCliente,
            asuntoEmail: "No Responder: Trabajo Finalizado",
            cuerpoEmail: textoMensaje,
        }
        await Axios.post(`${config.apiUrl}/api/enviarMail`,datosNotificacion,{headers:{'Content-Type':'application/json','Authorization': token},timeout: 5000})
        .then((response) => {
            titulo = "<strong>Atención</strong>";
            mensaje = response.data.message;
            icono = response.data.emailEnviado ? "success" : "error";
            SweetAlert(mensaje, icono,titulo)
        })
        .catch((error) => {
            titulo = "<strong>Atención</strong>";
            mensaje =  "<i>Atención: Hubo un problema al enviar la notificación.</i>";
            icono = "error";
            SweetAlert(mensaje, icono,titulo);
        });
    };
    /* Fin */
    const getDatosTrabajo = async () => {
        await Axios.get(`${config.apiUrl}/api/trabajos/` + id,{headers:{'Content-Type':'application/json','Authorization': token}})
        .then((response) => {
            setDetalleTrabajo(response.data.detalleTrabajo)
            setFechaTrabajo(response.data.fechaTrabajo)
            setProximaMantención(response.data.fechaProxMantencion)
            setRequiereNotificacion(response.data.requiereNotificacion)
            setCostoManoObra(response.data.costoManoObra)
            setCostoInsumos(response.data.costoInsumos)
            setCostoTotal(response.data.costoTotal)
            setObservacion(response.data.observacionTrabajo)
            setDetalleVehiculo(response.data.detalleVehiculo)
            setNombreCliente(response.data.nombreCliente)
            setTelefonoCliente(response.data.telefonoCliente)
            setEmailCliente(response.data.emailCliente)
            setPatenteVehiculo(response.data.patenteVehiculo)
            setNombreMecanico(response.data.nombreMecanico)
            setEstadoTrabajo(response.data.estadoTrabajo)
            setIdEstadoTrabajo(response.data.idEstadoTrabajo)
        })
        .catch((error) => {
            console.error("Atención: Hubo un problema al recuperar los datos del cliente.")
        });
    };
    return(
        <div className='card'>
            <div className='card-header'>
                <h3><span className='fa fa-wrench'></span>&nbsp;Detalle del Trabajo</h3>
            </div>
            <div className='card-body overflow-auto imprimir'>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className='row espaciadoVertical'>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <img src={avatar} alt='Logo Taller' className="imgVisible"/>
                            </div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <span className="textosNormal">{detalleTrabajo}</span>
                            </div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-calendar"></span>&nbsp;Fecha:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{fechaTrabajo}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-calendar"></span>&nbsp;Prox. Mantención:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{proximaMantencion === '01-01-1900' ? "No Informada" : proximaMantencion}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-envelope"></span>&nbsp;Req. Notificación:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{requiereNotificacion ? "SI" : "NO"}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-money"></span>&nbsp;Costo Mano Obra:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">$ {costoManoObra}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-money"></span>&nbsp;Costo Insumos:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">$ {costoInsumos}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-money"></span>&nbsp;Costo Total:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">$ {costoTotal}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-car"></span>&nbsp;Vehículo:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{detalleVehiculo}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-user"></span>&nbsp;Cliente:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{nombreCliente}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-phone"></span>&nbsp;Teléfono:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{telefonoCliente}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-address-card"></span>&nbsp;Patente:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{patenteVehiculo}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-pencil-square"></span>&nbsp;Observaciones:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{observacionTrabajo}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-user"></span>&nbsp;Realizado Por:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{nombreMecanico}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className='row espaciadoVertical'>
                            <div className='col-sm-5 col-md-5 col-lg-5 text-left'><span className="textos"><span className="fa fa-info-circle"></span>&nbsp;Estado:</span></div>
                            <div className="col-sm-5 col-md-5 col-lg-5">
                                <span className="textosNormal">{estadoTrabajo}</span>
                            </div>
                            <div className='col-sm-2 col-md-2 col-lg-2'></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <hr/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <DetalleInsumoTrabajo id={id}/>
                            </div>
                        </div>
                        {idTipoPerfil == 1 && idEstadoTrabajo === 3? 
                            <div className='row espaciadoVertical botonVisible'>
                                <div className='col-sm-2 col-md-2 col-lg-2'></div>
                                <div className='col-sm-4 col-md-4 col-lg-4 text-center'>
                                    <button className="btn-agregar botonVisible" onClick={()=>printDiv()}><span className='textosNormal'><span className='fa fa-print'></span>&nbsp;Imprimir</span></button>
                                </div>
                                <div className='col-sm-4 col-md-4 col-lg-4 text-center'>
                                    <button className="btn-agregar botonVisible" onClick={()=>enviarNotificacion()}><span className='textosNormal'><span className='fa fa-paper-plane'></span>&nbsp;Notificar</span></button>
                                </div>
                                <div className='col-sm-2 col-md-2 col-lg-2'></div>
                            </div>
                        : 
                            <div className='row espaciadoVertical botonVisible'>
                                <div className='col-sm-4 col-md-4 col-lg-4'></div>
                                <div className='col-sm-4 col-md-4 col-lg-4 text-center'>
                                    <button className="btn-agregar botonVisible" onClick={()=>printDiv()}><span className='textosNormal'><span className='fa fa-print'></span>&nbsp;Imprimir</span></button>
                                </div>
                                <div className='col-sm-4 col-md-4 col-lg-4'></div>
                            </div>
                        }
                        <div className='row espaciadoVertical divVisible'>
                            <div className='col-sm-12 col-md-12 col-lg-12 text-center'>
                                <p className="textosNormal divVisible">Taller Mecánico Leonhardt ©2023, Rolando Petersen 1421, Comuna Pudahuel - Fono: +56 953693372, Email: Nicholasleonhardt10@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DetalleTrabajo