import React from "react";
import config from '../../config'
import { useState, useEffect } from "react";    
import Axios from "axios";
import SweetAlert from "../SweetAlert";
import {validaVehiculo} from '../validaciones/Validaciones'

function RegistrarVehiculo(){
    const [nroPatente,setNroPatente] = useState("");
    const [idCliente,setIdCliente] = useState("");
    const [idModelo,setIdModelo] = useState(0);
    const [nombreCliente, setNombreCliente] = useState("");
    const [marcasList, setMarcas] = useState([]);
    const [modelosList, setModelos] = useState([]);
    const [clientesList, setClientes] = useState([]);
    const token = localStorage.getItem('token');
    let titulo = ""
    let mensaje = ""
    let icono = ""

    useEffect(() => {
        listarMarcas();
        listarClientes();
    },[]);
/*  Listamos las marcas de vehiculos habilitadas */
    const listarMarcas = async() =>{
        await Axios.get(`${config.apiUrl}/api/marcas/`,{headers: {'Authorization': token,},})
        .then((response) => {setMarcas(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de marcas:", error.response);});
};
/* Listamos los modelos de vehículos */
    const listarModelos = async(idMarca) =>{
        await Axios.get(`${config.apiUrl}/api/modelos/marca/` + idMarca,{headers: {'Authorization': token,},})
        .then((response) => {setModelos(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de marcas:", error.response);});
    };
/* Listamos los clientes */
    const listarClientes = async() =>{
        await Axios.get(`${config.apiUrl}/api/clientes`,{headers: {'Authorization': token,},})
        .then((response) => {setClientes(response.data);})
        .catch((error) => {console.error("Hubo un error al obtener la lista de clientes:", error.response);});
    };
/* Obtenemos los datos de un cliente por rut */
    const obtenerDatosCliente = async(rutCliente) =>{
        await Axios.get(`${config.apiUrl}/api/clientes/general/` + rutCliente,{headers: {'Authorization': token,},})
        .then((response) => {
            setIdCliente(response.data.idCliente);
            setNombreCliente(response.data.nombreCompletoCliente);
        })
        .catch((error) => {console.error("Hubo un error al obtener los datos del cliente:", error.response);});
    };
/* Fin */
    const registrarVehiculo = async () =>{
        if (validaVehiculo(nroPatente, idCliente, idModelo)){ // Validamos los datos
            titulo = "<strong>Atención</strong>";
            icono = "error";
            mensaje = validaVehiculo(nroPatente, idCliente, idModelo)
            SweetAlert(mensaje, "error","<strong>Atención</strong>")
        }else{
            const datosVehiculo = {
                patenteVehiculo: nroPatente.toUpperCase(),idPersona: idCliente,idModelo: idModelo
            }
            await Axios.post(`${config.apiUrl}/api/vehiculos/`,datosVehiculo,{headers:{'Content-Type':'application/json','Authorization': token}})
            .then((response) => {
                titulo = "<strong>Atención</strong>";
                mensaje = response.data.message;
                icono = response.data.registroCreado ? "success" : "error";
                SweetAlert(mensaje, icono,titulo);
            })
            .catch((error) => {
                titulo = "<strong>Atención</strong>";
                mensaje =  "<i>Atención: Hubo un problema al registrar el vehículo.</i>";
                icono = "error";
                SweetAlert(mensaje, icono,titulo);
            });
        }
    }
    return(
        <div className='card'>
        <div className='card-header'>
            <h3><span className='fa fa-car'></span>&nbsp;Registrar Vehiculo</h3>
        </div>
        <div className='card-body'>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-car"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {listarModelos(event.target.value)}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={2}>
                                <option value="">--SELECCIONE--</option>
                                {marcasList.map((val) => {
                                    return (
                                        <option value={val.idMarca} key={val.idMarca}>{val.nombreMarca}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-cog"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <select className="textosSelect textosNormal text-uppercase" onChange={(event) => {setIdModelo(event.target.value);}}
                                aria-label="Dropdown" aria-describedby="select-addon1" tabIndex={3}>
                                <option value="">--SELECCIONE--</option>
                                {modelosList.map((val) => {
                                    return (
                                        <option value={val.idModelo} key={val.idModelo}>{val.nombreModelo}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="nroPatente" name="nroPatente" type="text" placeholder="Nro. Patente" className="textosCajas textosNormal text-uppercase"
                            onChange={(event) => {setNroPatente(event.target.value);}} required maxLength={6} tabIndex={3}/>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'>
                            <span className="textosNormal">Ej: GKSB78</span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-id-card-o"></span></span></div>
                        <div className="col-sm-5 col-md-5 col-lg-5">
                            <input id="rut" name="rut" type="text" placeholder="Rut Cliente" className="textosCajas textosNormal text-uppercase"
                            maxLength={11} tabIndex={4} list="clientes" onChange={(event) => {obtenerDatosCliente(event.target.value)}}/>
                            <datalist id="clientes">
                                {clientesList.map((val) => {
                                    return (
                                        <option value={val.rutCliente} key={val.idCliente}>{val.rutCliente}</option>
                                    );
                                })}
                            </datalist>
                        </div>
                        <div className='col-sm-5 col-md-5 col-lg-5'><span className="textos">Ej.: 11111111-1</span></div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'><span className="textos"><span className="fa fa-user"></span></span></div>
                        <div className="col-sm-10 col-md-10 col-lg-10">
                            <span className="textosNormal">{nombreCliente}</span>
                        </div>
                    </div>
                    <div className='row espaciadoVertical'>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                        <div className="col-sm-8 col-md-8 col-lg-8 text-lg-center">
                            <button type="submit" className="btn-agregar" onClick={()=>{registrarVehiculo()}} tabIndex={5}>
                                <span className="textosNormal"><span className="fa fa-save"></span>&nbsp;Guardar</span>
                            </button>
                        </div>
                        <div className='col-sm-2 col-md-2 col-lg-2 text-left'></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default RegistrarVehiculo